/**
 * "unreachable" is a function designed to keep track of cases that should never
 * happen.
 *
 * In development, we elevate an error for such cases, so they are easily
 * visible. However, in production, we keep track of the error, but provide a
 * fallback value to continue the execution instead of crashing the app and
 * leaving the user with no choice but to restart.
 *
 * In the future we could integrate this with an error tracker like Sentry.
 */
export default function unreachable<T>(
  fallback: T,
  message = 'Unreachable case reached!',
): T {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    throw new Error(message);
  }

  return fallback;
}
