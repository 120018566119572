import type { ChargeFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const CHARGE_FRAGMENT: TypedDocumentNode<ChargeFragment> = gql`
  fragment Charge on Charge {
    id
    name
    amount
    frequency
    type
  }
`;

export default CHARGE_FRAGMENT;
