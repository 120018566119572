import { Body1 } from '$/components/Atoms/Typography';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import styled, { css } from 'styled-components';

import type { StyleProps } from './types';

export const ArrowIcon = styled(ArrowRightSLineIcon)`
  opacity: 0;
`;

export const Container = styled.div<StyleProps>`
  align-items: center;
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  cursor: pointer;
  display: flex;
  font-weight: ${({ theme }) => theme.customTheme.weights.medium};
  justify-content: space-between;
  text-decoration: none;
  transition: background-color 0.3s;
  padding: ${({ theme }) => theme.customTheme.spacing.lg};

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.customTheme.colors.arctic100};

    ${({ $isActive }) =>
      !$isActive &&
      css`
        ${ArrowIcon} {
          opacity: 1;
        }
      `};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${({ theme }) => theme.customTheme.colors.arctic100};
    `};

  ${({ $isRed }) =>
    $isRed &&
    css`
      color: ${({ theme }) => theme.customTheme.colors.error600};
    `};
`;

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Text = styled(Body1)`
  margin-left: ${({ theme }) => theme.customTheme.spacing.sm};

  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`;
