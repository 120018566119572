import analytics from '$/analytics';
import { memo, MouseEvent } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

function Button({
  className,
  disabled,
  label,
  onClick,
  icon,
  target,
  to,
  type,
  size = 'big',
  variant = 'primary',
  isLoading = false,
}: Props): JSX.Element {
  const onClickAction = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    if (onClick && !isLoading) onClick(e);
    if (label) {
      analytics?.track('app.button.clicked', {
        label,
      });
    }
  };
  if (to) {
    return (
      <Styled.Link
        href={to}
        passHref
        onClick={() => {
          if (label) {
            analytics?.track('app.button.clicked', {
              label,
            });
          }
        }}
        target={target}
        rel={target === '_blank' ? 'noreferrer noopener' : undefined}
      >
        <Styled.Container
          className={className}
          disabled={disabled}
          type={type}
          $variant={variant}
          $size={size}
        >
          {isLoading ? <Styled.LoaderIcon aria-label="Loading" /> : label}
        </Styled.Container>
      </Styled.Link>
    );
  }
  return (
    <Styled.Container
      type={type}
      onClick={(e) => onClickAction(e)}
      className={className}
      disabled={disabled}
      $variant={variant}
      $size={size}
    >
      {isLoading ? (
        <Styled.LoaderIcon aria-label="Loading">
          <Styled.LoaderDot />
          <Styled.LoaderDot />
          <Styled.LoaderDot />
        </Styled.LoaderIcon>
      ) : (
        <span>
          {icon}
          {label}
        </span>
      )}
    </Styled.Container>
  );
}

export default memo(Button);
