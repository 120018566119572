import type { RenewalOfferFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import RENEWAL_OFFER_DECISION_FRAGMENT from './RenewalOfferDecision';
import RENEWAL_OFFER_PRICINGS_FRAGMENT from './RenewalOfferPricings';

const RENEWAL_OFFER_FRAGMENT: TypedDocumentNode<RenewalOfferFragment> = gql`
  fragment RenewalOffer on RenewalOffer {
    id
    status
    effectiveFrom
    effectiveTo
    decision {
      ...RenewalOfferDecision
    }
    pricings {
      ...RenewalOfferPricings
    }
    zeroPointTermPresent
  }
  ${RENEWAL_OFFER_PRICINGS_FRAGMENT}
  ${RENEWAL_OFFER_DECISION_FRAGMENT}
`;

export default RENEWAL_OFFER_FRAGMENT;
