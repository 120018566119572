import type { ResidentFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeResident(
  input: WithoutNulls<ResidentFragment>,
) {
  return {
    cellPhone: input.cellPhone,
    name: `${input.firstName} ${input.lastName}`,
    emailAddress: input.emailAddress,
    id: input.id,
    status: input.status,
  };
}

export type Resident = ReturnType<typeof normalizeResident>;
