import type { OnboardingLeaseFragment } from '$/graphql/generated';
import normalizeOnboardingUnit from '$/model/OnboardingUnit';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeOnboardingRenewalOffer from './OnboardingRenewalOffer';

export default function normalizeOnboardingLease(
  input: WithoutNulls<OnboardingLeaseFragment>,
) {
  return {
    id: input.id,
    externalId: input.externalId,
    fromDate: parseISO(input.fromDate).getTime(),
    toDate: input.toDate && parseISO(input.toDate).getTime(),
    renewalOffer:
      input.renewalOffer && normalizeOnboardingRenewalOffer(input.renewalOffer),
    unit: normalizeOnboardingUnit(input.unit),
    community: input.community,
  };
}

export type OnboardingLease = ReturnType<typeof normalizeOnboardingLease>;
