import type {
  AcceptRenewalOfferMutation,
  AcceptRenewalOfferMutationVariables,
} from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import RENEWAL_OFFER_FRAGMENT from '../../fragments/RenewalOffer';
import RENEWAL_OFFER_PRICING_FRAGMENT from '../../fragments/RenewalOfferPricing';

const ACCEPT_RENEWAL_OFFER_MUTATION: TypedDocumentNode<
  AcceptRenewalOfferMutation,
  AcceptRenewalOfferMutationVariables
> = gql`
  mutation AcceptRenewalOffer(
    $renewalOfferPricingTermId: ID!
    $residentInfo: ResidentInfoInput!
    $addedRentableItems: [RentableItemCategoryInput]
    $removedRentableItems: [ChargeInput]
    $totalMonthlyChargeRange: MinMaxFloatRangeInput
    $removedResidents: [ResidentInput!]
    $addedResidents: Boolean
  ) {
    acceptRenewalOffer(
      input: {
        renewalOfferPricingTermId: $renewalOfferPricingTermId
        residentInfo: $residentInfo
        addedRentableItems: $addedRentableItems
        removedRentableItems: $removedRentableItems
        totalMonthlyChargeRange: $totalMonthlyChargeRange
        removedResidents: $removedResidents
        addedResidents: $addedResidents
      }
    ) {
      ... on RenewalOffer {
        ...RenewalOffer
      }
      ... on RenewalOfferDecisionMade {
        __typename
        message
      }
      ... on RenewalOfferDecisionExpiredNonExistent {
        __typename
        message
      }
    }
  }
  ${RENEWAL_OFFER_PRICING_FRAGMENT}
  ${RENEWAL_OFFER_FRAGMENT}
`;

export default ACCEPT_RENEWAL_OFFER_MUTATION;
