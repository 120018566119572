import useModal from '$/hooks/useModal';
import { useCallback, useState } from 'react';

export function useHandleMenu() {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const handleToggleMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpenMenu((prev) => !prev);
    },
    [setOpenMenu, setAnchorEl],
  );

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu]);

  return { openMenu, handleCloseMenu, handleToggleMenu, anchorEl };
}

export function useTermsModal() {
  const {
    isModalOpen: openTermsModal,
    onOpenModal: onOpenTermsModal,
    onCloseModal: onCloseTermsModal,
  } = useModal();

  return {
    onCloseTermsModal,
    onOpenTermsModal,
    openTermsModal,
  };
}
