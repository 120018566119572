import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  align-items: center;
  & svg {
    vertical-align: top;
    margin: ${({ theme }) => theme.customTheme.spacing.xs};
  }
`;
