import type { UserSummaryFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const USER_SUMMARY_FRAGMENT: TypedDocumentNode<UserSummaryFragment> = gql`
  fragment UserSummary on User {
    id
    cellPhone
    homePhone
    email
    firstName
    lastName
    communityLogoId
    resident {
      id
      status
    }
    inviteToken
  }
`;

export default USER_SUMMARY_FRAGMENT;
