import { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

function LogoItem({ className, children }: Props): JSX.Element {
  return <Styled.Container className={className}>{children}</Styled.Container>;
}

export default memo(LogoItem);
