export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    DownloadableFileUrlResponse: ['DownloadableFileUrl', 'Error'],
    ESignatureEmbedUrlResponse: ['ESignatureEmbedPayload'],
    Housing: ['Community', 'FloorPlan', 'Unit'],
    LoginResponse: [
      'AuthenticationPayload',
      'CommunityNotLive',
      'LoginServiceError',
    ],
    OnboardingUserResponse: ['OnboardingUser', 'UnknownOnboardingUser'],
    PaginationOutput: ['MatchingCommunitiesOutput'],
    RefreshAccessTokenResponse: [
      'AuthenticationPayload',
      'InvalidRefreshToken',
    ],
    RenewalOfferDecisionResponse: [
      'RenewalOffer',
      'RenewalOfferDecisionExpiredNonExistent',
      'RenewalOfferDecisionMade',
    ],
    RentersInsuranceInfoResponse: ['Error', 'RentersInsuranceInfo'],
    SendOneTimePasswordResponse: [
      'CommunityNotLive',
      'OneTimePassword',
      'SendCodeError',
      'UserNotFound',
    ],
    SubmitCommunityReviewResponse: [
      'CommunityReview',
      'CommunityReviewDisabled',
    ],
  },
};
export default result;
