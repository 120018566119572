/* eslint-disable @typescript-eslint/no-floating-promises */
import normalizeCurrentCommunity from '$/model/CurrentCommunity';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import GET_USER_COMMUNITY from '../../queries/user/getUserCommunity';
import initVar from '../../variables/init';
import useLoggedInStatus from '../auth/useLoggedInStatus';

export default function useCurrentCommunity() {
  const status = useLoggedInStatus();

  const [getUserCommunity, { data: raw, loading }] =
    useLazyQuery(GET_USER_COMMUNITY);

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser?.currentLease.community);
    return data && normalizeCurrentCommunity(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUserCommunity();
    }
  }, [getUserCommunity, status, init]);

  return { data: normalized, loading };
}
