/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { MoveOutInfoFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeAddress from './Address';

export default function normalizeMoveOutInfo(
  input: WithoutNulls<MoveOutInfoFragment>,
) {
  return {
    moveOutDate: input.moveOutDate
      ? parseISO(input.moveOutDate).getTime()
      : undefined,
    forwardingAddress: input.forwardingAddress
      ? normalizeAddress(input.forwardingAddress)
      : undefined,
    forwardingAddressFirstName: input.forwardingAddress
      ? input.forwardingAddressFirstName
      : undefined,
    forwardingAddressLastName: input.forwardingAddress
      ? input.forwardingAddressLastName
      : undefined,
    reasonForMoveOut: input.reasonForMoveOut,
    reasonForMoveOutAdditionalInfo: input.reasonForMoveOutAdditionalInfo,
  };
}

export type MoveOutInfo = ReturnType<typeof normalizeMoveOutInfo>;
