import { Body1 } from '$/components/Atoms/Typography';
import UtilizeSpacer from '$/styles/theme-styles';
import RouterLink from 'next/link';
import styled, { css } from 'styled-components';

export const Link = styled(RouterLink)<{ $isActive: boolean }>`
  color: ${({ theme }) => theme.customTheme.colors.grey500};
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  ${UtilizeSpacer({
    specificOption: {
      size: '2xs',
      direction: 'vertical',
    },
    type: 'padding',
  })}

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.customTheme.colors.night400};
    `};

  :hover {
    color: ${({ theme }) => theme.customTheme.colors.night900};
  }
`;

export const Text = styled(Body1).attrs({ as: 'span' })`
  color: inherit;
`;
