import type { LocationFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const LOCATION_FRAGMENT: TypedDocumentNode<LocationFragment> = gql`
  fragment Location on Location {
    latitude
    longitude
    name
    type
  }
`;

export default LOCATION_FRAGMENT;
