import { createTheme } from '@mui/material/styles';

const light = {
  colors: {
    night25: '#2827AC',
    night50: '#25239D',
    night100: '#21208E',
    night200: '#1E1D81',
    night300: '#1C1A76',
    night400: '#19186B',
    night500: '#171660',
    night600: '#141357',
    night700: '#12114E',
    night800: '#101046',
    night900: '#0B0B40',
    cobalt25: '#596FFF',
    cobalt50: '#5263FF',
    cobalt100: '#4758F1',
    cobalt200: '#3B4AD6',
    cobalt300: '#3140C9',
    cobalt400: '#2434BE',
    cobalt500: '#202FAB',
    cobalt600: '#1D2A9A',
    cobalt700: '#1A268B',
    cobalt800: '#18227D',
    cobalt900: '#151F70',
    arctic25: '#F8FDFF',
    arctic50: '#F5FCFF',
    arctic100: '#F0FAFF',
    arctic200: '#E5F6FF',
    arctic300: '#D9F1FF',
    arctic400: '#C0E8FF',
    arctic500: '#AAE0FF',
    arctic600: '#8ED6FF',
    arctic700: '#76CEFF',
    arctic800: '#56C3FF',
    arctic900: '#44BCFF',
    jade25: '#20796F',
    jade50: '#1D6E65',
    jade100: '#1B645C',
    jade200: '#185B53',
    jade300: '#16534C',
    jade400: '#144B45',
    jade500: '#12443E',
    jade600: '#103D38',
    jade700: '#0F3732',
    jade800: '#0D312D',
    jade900: '#0C2C29',
    success25: '#C8FFE1',
    success50: '#A8FDCE',
    success100: '#88F6BA',
    success200: '#86F1B8',
    success300: '#7AE5AC',
    success400: '#6FD09C',
    success500: '#64BB8C',
    success600: '#5AA87E',
    success700: '#519872',
    success800: '#498866',
    success900: '#3F805D',
    informational25: '#74D8FF',
    informational50: '#69C4FF',
    informational100: '#60B2FF',
    informational200: '#57A2FF',
    informational300: '#4F93FF',
    informational400: '#4886FF',
    informational500: '#4179E5',
    informational600: '#3A6DCF',
    informational700: '#3462BA',
    informational800: '#2F58A7',
    informational900: '#234FA5',
    error25: '#FF8B8B',
    error50: '#FF7E7E',
    error100: '#FF7272',
    error200: '#FF6868',
    error300: '#F05F5F',
    error400: '#DA5656',
    error500: '#C44D4D',
    error600: '#B14646',
    error700: '#9F3F3F',
    error800: '#8F3838',
    error900: '#893232',
    warning25: '#FFF8CE',
    warning50: '#FFF4B4',
    warning100: '#FFF19F',
    warning200: '#FFED88',
    warning300: '#FFEA72',
    warning400: '#FFD568',
    warning500: '#FFCF54',
    warning600: '#FBC743',
    warning700: '#F6BF30',
    warning800: '#F1B61D',
    warning900: '#E8AC11',
    grey25: '#FCFCFD',
    grey50: '#F9FAFB',
    grey100: '#F2F4F7',
    grey200: '#E4E7EC',
    grey300: '#D0D5DD',
    grey400: '#98A2B3',
    grey500: '#667085',
    grey600: '#475467',
    grey700: '#344054',
    grey800: '#F1B61D',
    grey900: '#101828',
    cream25: '#FCFCFD',
    cream50: '#F9FAFB',
    cream100: '#F2F4F7',
    cream200: '#E4E7EC',
    cream300: '#D0D5DD',
    black: '#000000',
    white: '#FFFFFF',
  },
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  spacing: {
    '2xs': '0.25rem',
    xs: '0.5rem',
    sm: '0.75rem',
    base: '1rem',
    md: '1.25rem',
    lg: '1.5rem',
    xl: '1.75rem',
    '2xl': '2rem',
    '2dot5xl': '2.5rem',
    '3xl': '3rem',
    '4xl': '4rem',
    '5xl': '5rem',
  },
  spacingV2: {
    desktop: {
      '3xs': '0.5rem',
      '2xs': '1rem',
      xs: '1.25rem',
      sm: '2rem',
      md: '2.5rem',
      lg: '3.25rem',
      xl: '4.75rem',
      '2xl': '6.25rem',
      '3xl': '9.5rem',
      '4xl': '12.5rem',
    },
    mobile: {
      '3xs': '0.25rem',
      '2xs': '0.75rem',
      xs: '1rem',
      sm: '1.25rem',
      md: '2rem',
      lg: '2.5rem',
      xl: '3.25rem',
      '2xl': '4.75rem',
      '3xl': '6.25rem',
      '4xl': '9.5rem',
    },
  },
  zIndex: {
    default: 0,
    menu: 10,
    navbar: 10,
    topBar: 10,
  },
};

export const customMaterialTheme = createTheme({
  typography: {
    fontFamily: `'Mint Grotesk'`,
  },
  palette: {
    primary: {
      main: light.colors.night400, // update
      light: light.colors.night400, // update
      dark: light.colors.night400,
    },
    secondary: {
      main: light.colors.warning100,
    },
  },
  customTheme: light,
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-outlined': {
            color: light.colors.black,
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: light.colors.black,
          },
        },
      },
    },
  },
});

export default customMaterialTheme;
