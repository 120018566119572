/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_USER_SUMMARY from '$/graphql/queries/user/getUserSummary';
import normalizeUserSummary from '$/model/UserSummary';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import initVar from '../../variables/init';
import useLoggedInStatus from '../auth/useLoggedInStatus';

export default function useUserSummary() {
  const status = useLoggedInStatus();

  const [getUserSummary, { data: raw, loading, error }] =
    useLazyQuery(GET_USER_SUMMARY);
  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser);
    return data && normalizeUserSummary(data);
  }, [raw]);
  useEffect(() => {
    if (init && status === 'logged-in') {
      getUserSummary();
    }
  }, [getUserSummary, status, init]);
  if (
    normalized &&
    (!localStorage['token'] ||
      localStorage.getItem('token') !== normalized?.inviteToken)
  ) {
    localStorage.setItem('token', normalized?.inviteToken);
  }
  return { data: normalized, loading, error };
}
