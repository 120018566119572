import type { MediaFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const MEDIA_FRAGMENT: TypedDocumentNode<MediaFragment> = gql`
  fragment Media on Media {
    publicId
  }
`;

export default MEDIA_FRAGMENT;
