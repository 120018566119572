import { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    top: 0;
  }

  50% {
    top: 0.4375rem;
  }

  100% {
    top: 0;
  }
`;

export default loading;
