import DefaultLogo from '$/assets/images/renew-logo.svg';
import { Body1 } from '$/components/Atoms/Typography';
import DefaultMenu from '$/components/Organisms/Menu';
import ArrowLeft from 'remixicon-react/ArrowLeftSLineIcon';
import Information from 'remixicon-react/InformationLineIcon';
import styled, { css } from 'styled-components';

export const Bar = styled.div<{ $withoutBorder?: boolean }>`
  background: ${({ theme }) => theme.customTheme.colors.white};
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.3fr;
  min-height: 3rem;
  padding: ${({ theme }) =>
    `${theme.customTheme.spacing.sm} ${theme.customTheme.spacing.base}`};
  width: 100%;

  ${({ $withoutBorder }) =>
    !$withoutBorder &&
    css`
      box-shadow: inset 0rem -0.0625rem 0rem rgba(0, 0, 0, 0.1);
    `}
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  cursor: pointer;
  max-height: 1.5rem;
  padding: 0;
`;

export const Center = styled.div`
  grid-column: 2;
  justify-self: center;
  align-self: center;
  display: flex;
`;

export const Container = styled.div``;

export const Menu = styled(DefaultMenu)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.customTheme.zIndex.topBar};
`;

export const Left = styled.div`
  align-self: center;
  display: flex;
  grid-column: 1;
  justify-self: start;
`;

export const Logo = styled(DefaultLogo)`
  height: 1.25rem;
`;

const iconStyles = css`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
  ${iconStyles}
`;

export const InformationIcon = styled(Information)`
  ${iconStyles}
`;

export const Right = styled.div`
  align-self: center;
  display: flex;
  grid-column: 3;
  justify-self: end;
`;

export const Text = styled(Body1)`
  font-size: 1rem;
  line-height: 150%;
  margin: 0;
`;
