import {
  ContactData,
  HouseholdData,
} from '$/containers/Views/RenewalOffer/UpdateInfo/types';
import { ChargeInput, RentableItemCategoryInput } from '$/graphql/generated';
import React, { createContext, useState } from 'react';

export type StateOptions = {
  interestedTerm?: {
    id: string;
    amount: {
      baseRent: number;
      totalMonthlyCharge: number;
    };
    name?: string;
    charges?: ChargeInput[];
    communityId?: string;
    communityName?: string;
  };
  rentableItems?: {
    added?: RentableItemCategoryInput[];
    removed?: ChargeInput[];
  };
  updatedInfo?: {
    contactInfo: ContactData;
    householdInfo: HouseholdData;
  };
};
interface Global {
  globalState: StateOptions;
  setGlobalState: React.Dispatch<React.SetStateAction<StateOptions>>;
}

export const GlobalContext = createContext<Global>({} as Global);

export const GlobalProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [globalState, setGlobalState] = useState<StateOptions>({});

  return (
    <GlobalContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </GlobalContext.Provider>
  );
};
