import type { OnboardingRenewalOfferFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

export default function normalizeOnboardingRenewalOffer(
  input: WithoutNulls<OnboardingRenewalOfferFragment>,
) {
  return {
    effectiveTo: input.effectiveTo && parseISO(input.effectiveTo).getTime(),
    effectiveFrom:
      input.effectiveFrom && parseISO(input.effectiveFrom).getTime(),
    status: input.status,
    zeroPointTermPresent: input.zeroPointTermPresent,
  };
}

export type OnboardingRenewalOffer = ReturnType<
  typeof normalizeOnboardingRenewalOffer
>;
