import type { AddressFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const ADDRESS_FRAGMENT: TypedDocumentNode<AddressFragment> = gql`
  fragment Address on Address {
    city
    secondary
    state
    street
    zipCode
    id
  }
`;

export default ADDRESS_FRAGMENT;
