import MenuLink from '$/components/Molecules/MenuLink';
import { PricingType } from '$/graphql/generated';
import useMenuHandler from '$/hooks/useMenuHandler';
import { memo } from 'react';

import * as Styled from './styles';
import { Props } from './types';

function Menu({
  className,
  handleLogout,
  handleReset,
  handleOnClose,
  open,
  pathname,
  hasReset,
}: Props): JSX.Element {
  const { handleUrl, handleMailTo } = useMenuHandler({
    handleOnClose,
    pathname,
  });

  return (
    <Styled.Drawer open={open} onClose={handleOnClose} id="drawer-menu-mobile">
      <Styled.Container className={className}>
        <Styled.ButtonWrapper>
          <Styled.CloseButton onClick={handleOnClose}>
            <Styled.CloseIcon />
          </Styled.CloseButton>
        </Styled.ButtonWrapper>

        <Styled.Links>
          <MenuLink
            Icon={Styled.HomeIcon}
            isActive={pathname === '/'}
            label="Home"
            withArrow
            onClick={() => handleUrl('/')}
          />
          <MenuLink
            Icon={Styled.LeaseIcon}
            isActive={pathname === '/lease'}
            label="Your Lease"
            withArrow
            onClick={() => handleUrl('/lease')}
          />
          <MenuLink
            Icon={Styled.DollarIcon}
            isActive={pathname === '/rewards'}
            label="Rewards wallet"
            withArrow
            onClick={() => handleUrl('/rewards')}
          />
          <MenuLink
            Icon={Styled.RenewalOfferIcon}
            isActive={pathname === '/renewal-offer'}
            label="Your Renewal Offers"
            withArrow
            onClick={() => handleUrl('/renewal-offer')}
          />
          <MenuLink
            Icon={Styled.BrowsePropertiesIcon}
            isActive={pathname.includes('/browse-properties')}
            label="Browse properties"
            withArrow
            onClick={() => {
              handleUrl('/browse-properties');
            }}
          />
          <MenuLink
            Icon={Styled.QuestionIcon}
            isActive={pathname === '/help'}
            label="Help"
            withArrow
            onClick={() => handleUrl('/help')}
          />
          <MenuLink
            Icon={Styled.FavoritesIcon}
            isActive={pathname === '/favorites'}
            label="Favorites"
            withArrow
            onClick={() => handleUrl('/favorites')}
          />
          <MenuLink
            Icon={Styled.ChatIcon}
            label="Contact us"
            withArrow
            onClick={() => handleMailTo('mailto:residentsupport@heyrenew.com')}
          />
          {hasReset && (
            <>
              <MenuLink
                Icon={Styled.RefreshIcon}
                label="Reset"
                withArrow
                onClick={() => handleReset(PricingType.Standard)}
              />
              <MenuLink
                Icon={Styled.RefreshIcon}
                label="Reset Dynamic"
                withArrow
                onClick={() => handleReset(PricingType.Dynamic)}
              />
              <MenuLink
                Icon={Styled.RefreshIcon}
                label="Reset Without Rewards"
                withArrow
                onClick={() => handleReset(PricingType.Standard, false)}
              />
            </>
          )}
          <MenuLink
            Icon={Styled.LogoutIcon}
            isRed
            label="Logout"
            onClick={handleLogout}
            withArrow
          />
        </Styled.Links>
        <Styled.ButtonLink
          label="Terms of Use"
          target="_blank"
          to="https://heyrenew.com/terms-of-use"
          variant="floating"
        />
      </Styled.Container>
    </Styled.Drawer>
  );
}

export default memo(Menu);
