import type { IncentiveFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeIncentiveRedemption from './IncentiveRedemption';

export default function normalizeIncentives(
  input: WithoutNulls<IncentiveFragment>,
) {
  return {
    balance: input.balance / 100,
    redemptionDeadline: input.redemptionDeadline
      ? parseISO(input.redemptionDeadline).getTime()
      : undefined,
    redemptions: input.redemptions.map((redemption) =>
      normalizeIncentiveRedemption(redemption),
    ),
  };
}

export type Incentive = ReturnType<typeof normalizeIncentives>;
