import useModal from '$/hooks/useModal';
import { useCallback, useState } from 'react';

export function useHandleMenu() {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu]);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, [setOpenMenu]);

  return { openMenu, handleCloseMenu, handleOpenMenu };
}

export function useTermsModal() {
  const {
    isModalOpen: openTermsModal,
    onOpenModal: onOpenTermsModal,
    onCloseModal: onCloseTermsModal,
  } = useModal();

  return {
    onCloseTermsModal,
    onOpenTermsModal,
    openTermsModal,
  };
}
