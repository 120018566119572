/* eslint-disable @typescript-eslint/no-floating-promises */
import useLoggedInStatus from '$/graphql/hooks/auth/useLoggedInStatus';
import GET_USER from '$/graphql/queries/user/getUser';
import initVar from '$/graphql/variables/init';
import normalizeUser from '$/model/User';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

/**
 * If user session tokens exist in localStorage, attempt to make an
 * authenticated GQL call for "currentUser" and return that payload.
 */
export default function useCurrentUser() {
  const status = useLoggedInStatus();
  const [getUser, { data: raw, loading }] = useLazyQuery(GET_USER);

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser);
    return data && normalizeUser(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUser();
    }
  }, [getUser, status, init]);
  if (
    normalized &&
    (!localStorage['token'] ||
      localStorage.getItem('token') !== normalized?.inviteToken)
  ) {
    localStorage.setItem('token', normalized?.inviteToken);
  }

  return { data: normalized, loading };
}
