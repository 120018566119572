import type { ResetAllRenewalOffersMutation } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const RESET_ALL_RENEWAL_OFFER_MUTATION: TypedDocumentNode<ResetAllRenewalOffersMutation> = gql`
  mutation ResetAllRenewalOffers($pricingType: PricingType, $rewards: Boolean) {
    resetAllRenewalOffers(
      input: { pricingType: $pricingType, rewards: $rewards }
    ) {
      id
    }
  }
`;

export default RESET_ALL_RENEWAL_OFFER_MUTATION;
