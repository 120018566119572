import type { AddressFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeAddress(input: WithoutNulls<AddressFragment>) {
  return {
    city: input.city,
    secondary: input.secondary,
    state: input.state,
    street: input.street,
    zipCode: input.zipCode,
    id: input.id,
  };
}

export type Address = ReturnType<typeof normalizeAddress>;
