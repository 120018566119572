import type { CurrentCommunityFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import GEOLOCATED_ADDRESS_FRAGMENT from './GeolocatedAddress';

const CURRENT_COMMUNITY_FRAGMENT: TypedDocumentNode<CurrentCommunityFragment> = gql`
  fragment CurrentCommunity on Community {
    id
    name
    address {
      ...GeolocatedAddress
    }
    propertyManager {
      name
      id
    }
    moveOutReasonOptions {
      id
      community_id
      description
    }
    noticeToVacateDeadlineDaysBeforeLeaseEnd
    googlePlacesReviewUrl
  }
  ${GEOLOCATED_ADDRESS_FRAGMENT}
`;

export default CURRENT_COMMUNITY_FRAGMENT;
