import type { MediaFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeMedia(input: WithoutNulls<MediaFragment>) {
  return {
    id: input.publicId,
    url: `/${input.publicId}`,
  };
}

export type Media = ReturnType<typeof normalizeMedia>;
