import type { IncentiveRedemptionFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const INCENTIVE_REDEMPTION_FRAGMENT: TypedDocumentNode<IncentiveRedemptionFragment> = gql`
  fragment IncentiveRedemption on IncentiveRedemption {
    amount
  }
`;

export default INCENTIVE_REDEMPTION_FRAGMENT;
