import type { IncentiveRedemptionFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeIncentiveRedemption(
  input: WithoutNulls<IncentiveRedemptionFragment>,
) {
  return {
    amount: input.amount,
  };
}

export type IncentiveRedemption = ReturnType<
  typeof normalizeIncentiveRedemption
>;
