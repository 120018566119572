import type { UserPreferenceFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

import normalizeLocation from './Location';

export default function normalizeUserPreference(
  input: WithoutNulls<UserPreferenceFragment>,
) {
  return {
    location: input.location ? normalizeLocation(input.location) : undefined,
    minBed: input.minBed ?? 0,
    minBath: input.minBath ?? 0,
    minBudget: input.minBudget ?? 0,
    maxBudget: input.maxBudget ?? 0,
    amenities: input.amenities ?? [],
  };
}

export type UserPreference = ReturnType<typeof normalizeUserPreference>;
