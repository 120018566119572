import { Text } from '$/components/Atoms/Typography';
import { till } from '$/styles/responsive';
import UtilizeSpacer from '$/styles/theme-styles';
import styled, { css } from 'styled-components';

const findPercentage = (pixels: number) => {
  const screenWidth = 1440; // width of figma mocks;
  return (pixels / screenWidth) * 100;
};
export const Container = styled.div<{ $positionAbsolute?: boolean }>`
  position: absolute;
  width: 100%;
  ${({ $positionAbsolute }) =>
    !$positionAbsolute &&
    css`
      position: relative;
    `}
`;

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ProgressBar = styled.div<{
  $current: boolean;
  $previous: boolean;
  $next: boolean;
  $width?: number;
}>`
  ${({ $width }) =>
    $width
      ? css`
          border: 1px solid ${({ theme }) => theme.customTheme.colors.jade25};
          border-radius: 8px;
          width: ${findPercentage($width)}%;
          height: 7px;
          margin-right: 8px;
          &:nth-last-child(2) {
            margin-right: 0;
          }
        `
      : css`
          display: none;
        `}

  ${({ $current }) =>
    $current &&
    css`
      border: 1px solid ${({ theme }) => theme.customTheme.colors.jade25};
      background: ${({ theme }) => theme.customTheme.colors.jade25};
    `}

  ${({ $next }) =>
    $next &&
    css`
      border: 1px solid ${({ theme }) => theme.customTheme.colors.grey300};
      background: ${({ theme }) => theme.customTheme.colors.grey300};
    `}

    ${({ $previous }) =>
    $previous &&
    css`
      border: 1px solid ${({ theme }) => theme.customTheme.colors.jade400};
      background: ${({ theme }) => theme.customTheme.colors.jade400};
    `}
`;

export const PositionDefiner = styled(Text)`
  ${UtilizeSpacer({
    specificOption: { size: '3xs', direction: 'top' },
    type: 'margin',
  })}
  ${till.tabletLandscape`
    margin: 8px auto 0;
  `};
`;
