import type { CurrentCommunityFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

import normalizeAddress from './Address';

export default function normalizeCurrentCommunity(
  input: WithoutNulls<CurrentCommunityFragment>,
) {
  return {
    id: input.id,
    name: input.name,
    address: normalizeAddress(input.address),
    propertyManager: input.propertyManager,
    moveOutReasonOptions: input.moveOutReasonOptions,
    noticeToVacateDeadlineDaysBeforeLeaseEnd:
      input.noticeToVacateDeadlineDaysBeforeLeaseEnd,
    googlePlacesReviewUrl: input.googlePlacesReviewUrl,
  };
}

export type CurrentCommunity = ReturnType<typeof normalizeCurrentCommunity>;
