import type { CommunitySummaryFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import GEOLOCATED_ADDRESS_FRAGMENT from './GeolocatedAddress';
import MEDIA_FRAGMENT from './Media';

const COMMUNITY_SUMMARY_FRAGMENT: TypedDocumentNode<CommunitySummaryFragment> = gql`
  fragment CommunitySummary on Community {
    id
    name
    propertyManager {
      id
      name
    }
    address {
      ...GeolocatedAddress
    }
    rentRange {
      min
      max
    }
    bathRange {
      min
      max
    }
    bedsRange {
      min
      max
    }
    coverImage {
      ...Media
    }
    pointValue
    availabilityDate
    tourLink
  }
  ${GEOLOCATED_ADDRESS_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;

export default COMMUNITY_SUMMARY_FRAGMENT;
