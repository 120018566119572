import GraphQlErrorBoundary from '$/components/Atoms/GraphQlErrorBoundary';
import { ApolloProvider } from '@apollo/client';
import React from 'react';

import useConnect from './connect';
import Initializer from './Initializer';
import type { Props } from './types';

export default function GraphQLProvider({ children }: Props): JSX.Element {
  const { client, showError } = useConnect();

  return (
    <ApolloProvider client={client}>
      <GraphQlErrorBoundary showError={showError || false}>
        <Initializer>{children}</Initializer>
      </GraphQlErrorBoundary>
    </ApolloProvider>
  );
}
