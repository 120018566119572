import * as Styled from './styles';
import type { Props } from './types';

const Footer = ({ children, fixedButtonPosition }: Props): JSX.Element => (
  <Styled.Footer $fixedButtonPosition={fixedButtonPosition}>
    {children}
  </Styled.Footer>
);

export default Footer;
