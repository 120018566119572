import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize};

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Mint Grotesk', -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* 
   * Google Maps API shows a modal with predictions and
   * it seems material UI modal z-index is higher than the one
   * specified for the predictions one, so we added this to make
   * sure it shows over.
   */
  .pac-container {
    z-index: 100000 !important;
  }
  
  #__next {
    display: flex;
    min-height: 100vh;
    
    > * {
      flex: 1;
    }
  }

  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }


  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #19186B !important; 
    color: #19186B !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #19186B !important; 
  }
  #menu-reasonForMoveOut {
    .MuiList-padding{ 
      padding: 0;
    }

    .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover, .MuiListItem-button:hover {
      background-color: #CAD5D9 !important;
    }
  }

  .MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .lg-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
  
`;

export default GlobalStyle;
