/* eslint-disable @typescript-eslint/naming-convention */
import useLoggedInStatus from '$/graphql/hooks/auth/useLoggedInStatus';
import deeplinkPathname from '$/graphql/variables/deeplink';
import globalAlternateStates from '$/graphql/variables/error';
import initVar from '$/graphql/variables/init';
import { useReactiveVar } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useLoggedInRedirect() {
  const {
    replace,
    query: { move_out, token: inviteToken },
  } = useRouter();
  const status = useLoggedInStatus();
  const init = useReactiveVar(initVar);
  const alternateState = useReactiveVar(globalAlternateStates);

  const deeplink = useReactiveVar(deeplinkPathname);

  const url = deeplink.pathname || '/renewal-offer';

  useEffect(() => {
    if (alternateState.invalidRefreshToken) {
      globalAlternateStates({
        invalidRefreshToken: false,
      });
      if (inviteToken) {
        void replace({
          pathname: `/login`,
          query: {
            token: inviteToken,
          },
        });
      }
      void replace('/logout');
    } else if (status === 'logged-in' && init) {
      void replace({
        pathname: url,
        query: move_out && { move_out },
      }).then(() => {
        if (deeplink.pathname) {
          deeplinkPathname({ pathname: undefined });
        }
      });
    }
  }, [
    replace,
    status,
    init,
    url,
    move_out,
    deeplink.pathname,
    alternateState.invalidRefreshToken,
    inviteToken,
  ]);
}
