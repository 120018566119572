import type { RenewalDataFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import INCENTIVE_FRAGMENT from './Incentive';

const RENEWAL_DATA_FRAGMENT: TypedDocumentNode<RenewalDataFragment> = gql`
  fragment RenewalData on User {
    incentives {
      ...Incentive
    }
  }

  ${INCENTIVE_FRAGMENT}
`;

export default RENEWAL_DATA_FRAGMENT;
