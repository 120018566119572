import { makeVar } from '@apollo/client';

const globalAlternateStates = makeVar<{
  permissionDenied?: boolean;
  invalidRefreshToken?: boolean;
  toastMessage?: string | null;
  showError?: boolean;
}>({
  permissionDenied: false,
  invalidRefreshToken: false,
  showError: false,
});

export default globalAlternateStates;
