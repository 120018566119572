import type { OnboardingUnit } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const ONBOARDING_UNIT_FRAGMENT: TypedDocumentNode<OnboardingUnit> = gql`
  fragment OnboardingUnit on OnboardingUnit {
    beds
    baths
    name
    community
  }
`;

export default ONBOARDING_UNIT_FRAGMENT;
