import SingleFamilyHomeImage from '$/assets/images/illustrations/update/cityscape.svg';
import DefaultMaintanenceImage from '$/assets/images/illustrations/update/kitchen.svg';
import MaxWidth from '$/components/Atoms/MaxWidth';
import { H5 } from '$/components/Atoms/Typography';
import GuestLayout from '$/containers/Layout/Guest';
import MainLayout from '$/containers/Layout/Main';
import { from } from '$/styles/responsive';
import UtilizeSpacer from '$/styles/theme-styles';
import styled, { css } from 'styled-components';

const imageStyles = css`
  ${UtilizeSpacer({
    options: [
      { size: 'md', direction: [0] },
      { size: 'auto', direction: [1] },
    ],
    type: 'margin',
  })}
  max-width: 342px;
`;
export const ErrorImage = styled(SingleFamilyHomeImage)`
  ${imageStyles}
`;

export const MaintenanceImage = styled(DefaultMaintanenceImage)`
  ${imageStyles}
`;

export const GuestContainer = styled(GuestLayout)`
  width: 100%;
`;

export const MainContainer = styled(MainLayout)`
  width: 100%;
`;

export const Content = styled(MaxWidth)`
  margin-top: ${({ theme }) => theme.customTheme.spacing['2xl']};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Title = styled(H5).attrs(() => ({ as: 'h1' }))`
  ${UtilizeSpacer({
    specificOption: { size: '3xs', direction: 'bottom' },
    type: 'margin',
  })}
  text-align: center;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  text-align: center;
  font-weight: ${({ theme }) => theme.customTheme.weights.regular};
  line-height: 24px;
`;

export const Link = styled.a<{ $underlined?: boolean }>`
  color: ${({ theme }) => theme.customTheme.colors.night400};
  font-weight: 600;

  ${({ $underlined }) =>
    !$underlined &&
    css`
      text-decoration: none;
    `}
`;

export const List = styled.ul`
  margin: ${({ theme }) => theme.customTheme.spacing.base} 0;
  padding: 0;

  ${from.tabletLandscape`
    margin: ${({ theme }) => theme.customTheme.spacing.md} 0;
  `};
`;

export const ListItem = styled.li`
  list-style-position: inside;
`;
