import type { MoveOutInfo } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import ADDRESS_FRAGMENT from './Address';

const MOVEOUT_INFO_FRAGMENT: TypedDocumentNode<MoveOutInfo> = gql`
  fragment MoveOutInfo on MoveOutInfo {
    moveOutDate
    forwardingAddressFirstName
    forwardingAddressLastName
    reasonForMoveOut
    reasonForMoveOutAdditionalInfo
    forwardingAddress {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export default MOVEOUT_INFO_FRAGMENT;
