import type { UpgradeUnitFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeAddress from './Address';
import normalizeGeolocatedAddress from './GeolocatedAddress';
import normalizeMedia from './Media';

export default function normalizeUpgradeUnit(
  input: WithoutNulls<UpgradeUnitFragment>,
) {
  return {
    id: input.id,
    address: normalizeAddress(input.address),
    beds: input.beds,
    baths: input.baths,
    name: input.name,
    media: input.media.map(normalizeMedia),
    minSqft: input.minSqft,
    maxSqft: input.maxSqft,
    rent: input.rent,
    securityDeposit: input.securityDeposit,
    pointValue: input.pointValue / 100,
    availabilityDate: input.availabilityDate
      ? parseISO(input.availabilityDate).getTime()
      : undefined,
    community: {
      id: input.community.id,
      name: input.community.name,
      address: normalizeGeolocatedAddress(input.community.address),
      propertyManager: input.community.propertyManager.name,
    },
    onSiteLaundry: input.keyAmenities.onSiteLaundry ? 'Yes' : 'No',
    garage: input.keyAmenities.garage ? 'Yes' : 'No',
    patioBalcony: input.keyAmenities.patioBalcony ? 'Yes' : 'No',
    floorPlanImage: input.floorPlan.mapImageId
      ? `/${input.floorPlan.mapImageId}`
      : undefined,
  };
}

export type UpgradeUnit = ReturnType<typeof normalizeUpgradeUnit>;
