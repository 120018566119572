import { from } from '$/styles/responsive';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

import { Size, Weight } from './types';

export const sizing = {
  display: {
    desktop: {
      '2xs': {
        size: '1.5rem',
        lineHeight: '32px',
      },
      xs: {
        size: '1.5rem',
        lineHeight: '32px',
      }, // h6
      sm: {
        size: '1.875rem',
        lineHeight: '38px',
      }, // h5
      md: {
        size: '2.25rem',
        lineHeight: '44px',
      }, // h4
      lg: {
        size: '2.5rem',
        lineHeight: '60px',
      }, // h3
      xl: {
        size: '3.75rem',
        lineHeight: '72px',
      }, // h2
      '2xl': {
        size: '4.5rem',
        lineHeight: '90px',
      }, // h1
    },
    mobile: {
      '2xs': {
        size: '1.25rem',
        lineHeight: '30px',
      },
      xs: {
        size: '1.25rem',
        lineHeight: '30px',
      }, // h6
      sm: {
        size: '1.5rem',
        lineHeight: '32px',
      }, // h5
      md: {
        size: '1.875rem',
        lineHeight: '38px',
      }, // h4
      lg: {
        size: '2.25rem',
        lineHeight: '44px',
      }, // h3
      xl: {
        size: '2.5rem',
        lineHeight: '60px',
      }, // h2
      '2xl': {
        size: '3.75rem',
        lineHeight: '72px',
      }, // h1
    },
  },
  text: {
    desktop: {
      '2xs': {
        size: '0.625rem',
        lineHeight: '16px',
      },
      xs: {
        size: '0.75rem',
        lineHeight: '18px',
      },
      sm: {
        size: '0.875rem',
        lineHeight: '20px',
      },
      md: {
        size: '1rem',
        lineHeight: '24px',
      },
      lg: {
        size: '1.125rem',
        lineHeight: '28px',
      },
      xl: {
        size: '1.25rem',
        lineHeight: '30px',
      },
      '2xl': {
        size: '1.25rem',
        lineHeight: '30px',
      },
    },
    mobile: {
      '2xs': {
        size: '0.625rem',
        lineHeight: '16px',
      },
      xs: {
        size: '0.75rem',
        lineHeight: '18px',
      },
      sm: {
        size: '0.875rem',
        lineHeight: '20px',
      },
      md: {
        size: '1rem',
        lineHeight: '24px',
      },
      lg: {
        size: '1rem',
        lineHeight: '24px',
      },
      xl: {
        size: '1.125rem',
        lineHeight: '28px',
      },
      '2xl': {
        size: '1.125rem',
        lineHeight: '28px',
      },
    },
  },
};

export const weights = (
  weight: Weight,
): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
  ${({ theme }) => theme.customTheme.weights[weight]}
`;

export const TypographyStyles = (
  type: 'display' | 'text',
  size?: Size,
  weight?: Weight,
): FlattenInterpolation<ThemeProps<DefaultTheme>> =>
  css`
    font-size: ${size && sizing[type].mobile[size].size};
    line-height: ${size && sizing[type].mobile[size].lineHeight};
    font-weight: ${weight && weights(weight)};
    ${from.tabletLandscape`
      font-size: ${size && sizing[type].desktop[size].size};
      line-height: ${size && sizing[type].desktop[size].lineHeight};
    `}
  `;

// Headline
export const headlineCommonStyles = (
  size: Size,
): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  ${TypographyStyles('display', size, 'bold')}
`;

export const H1 = styled.h1`
  ${headlineCommonStyles('2xl')};
`;

export const H2 = styled.h2`
  ${headlineCommonStyles('xl')};
`;

export const H3 = styled.h3`
  ${headlineCommonStyles('lg')};
`;

export const H4 = styled.h4`
  ${headlineCommonStyles('md')};
`;

export const H5 = styled.h5`
  ${headlineCommonStyles('sm')};
`;

export const H6 = styled.h6`
  ${headlineCommonStyles('xs')};
`;

// Display 2xl/Semibold
export const Display2xl = styled.h1<{
  $semibold?: boolean;
}>`
  ${({ $semibold }) =>
    css`
      ${TypographyStyles('display', '2xl', $semibold ? 'bold' : undefined)}
    `}
`;

// Headline
export const textCommonStyles = (
  defaultSize?: Size,
  size?: Size,
  underline?: boolean,
  weight?: Weight,
  italic?: boolean,
): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
  text-decoration-line: ${underline && `underline`};
  font-style: ${italic && `italic`};
  ${TypographyStyles('text', size || defaultSize, weight || 'medium')}
`;

export const Text = styled.span<{
  $underlined?: boolean;
  $italic?: boolean;
  $size?: Size;
  $weight?: Weight;
}>`
  ${({ $size, $weight, $underlined, $italic }) =>
    textCommonStyles('md', $size, $underlined, $weight, $italic)}
`;
// Text md
export const Body1 = styled.p<{
  $underlined?: boolean;
  $italic?: boolean;
  $size?: Size;
  $weight?: Weight;
}>`
  ${({ $size, $weight, $underlined, $italic }) =>
    textCommonStyles('md', $size, $underlined, $weight, $italic)}
`;

export const Body2 = styled.p<{
  $underlined?: boolean;
  $italic?: boolean;
  $size?: Size;
  $weight?: Weight;
}>`
  ${({ $size, $weight, $underlined, $italic }) =>
    textCommonStyles('sm', $size, $underlined, $weight, $italic)}
`;

export const Body3 = styled.p<{
  $underlined?: boolean;
  $italic?: boolean;
  $size?: Size;
  $weight?: Weight;
}>`
  ${({ $size, $weight, $underlined, $italic }) =>
    textCommonStyles('xs', $size, $underlined, $weight, $italic)}
`;

export const Bold = styled.span<{ $block?: boolean }>`
  ${TypographyStyles('text', undefined, 'bold')}
  ${({ $block }) =>
    $block &&
    css`
      display: block;
    `}
`;

export const Italicized = styled.span<{ $block?: boolean }>`
  ${() => textCommonStyles(undefined, undefined, undefined, undefined, true)}
  ${({ $block }) =>
    $block &&
    css`
      display: block;
    `}
`;

// Caption - Text xs/Normal
export const Caption = styled.span`
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  ${TypographyStyles('text', 'xs', 'medium')}
  display: inline-block;
`;

// Overline
export const Overline = styled.span`
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  ${TypographyStyles('text', 'sm', 'medium')}
`;

// Subtitle
export const Subtitle = styled.p`
  color: ${({ theme }) => theme.customTheme.colors.grey900};
  ${TypographyStyles('text', 'lg', 'medium')}
`;
