import * as Styled from './styles';
import type { Props } from './types';

export default function SpaceBetween({
  children,
  className,
}: Props): JSX.Element {
  return (
    <Styled.SpaceBetween className={className}>{children}</Styled.SpaceBetween>
  );
}
