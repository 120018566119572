import type { IncentiveFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import INCENTIVE_REDEMPTION_FRAGMENT from './IncentiveRedemption';

const INCENTIVE_FRAGMENT: TypedDocumentNode<IncentiveFragment> = gql`
  fragment Incentive on Incentive {
    balance
    redemptionDeadline
    redemptions {
      ...IncentiveRedemption
    }
  }
  ${INCENTIVE_REDEMPTION_FRAGMENT}
`;

export default INCENTIVE_FRAGMENT;
