import styled, { css } from 'styled-components';

const MaxWidth = styled.div<{ $variant?: 'small' | 'big' }>`
  margin-right: auto;
  margin-left: auto;
  max-width: 78.5rem;
  padding-right: ${({ theme }) => theme.customTheme.spacing.md};
  padding-left: ${({ theme }) => theme.customTheme.spacing.md};
  width: 100%;

  ${({ $variant }) =>
    $variant === 'small' &&
    css`
      max-width: 39.8125rem;
    `}
`;

export default MaxWidth;
