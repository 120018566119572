import type { OnboardingLeaseFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import ONBOARDING_COMMUNITY_FRAGMENT from './OnboardingCommunity';
import ONBOARDING_RENEWAL_OFFER_FRAGMENT from './OnboardingRenewalOffer';
import ONBOARDING_UNIT_FRAGMENT from './OnboardingUnit';

const ONBOARDING_LEASE_FRAGMENT: TypedDocumentNode<OnboardingLeaseFragment> = gql`
  fragment OnboardingLease on OnboardingLease {
    id
    externalId
    fromDate
    toDate
    renewalOffer {
      ...OnboardingRenewalOffer
    }
    unit {
      ...OnboardingUnit
    }
    community {
      ...OnboardingCommunity
    }
  }
  ${ONBOARDING_UNIT_FRAGMENT}
  ${ONBOARDING_COMMUNITY_FRAGMENT}
  ${ONBOARDING_RENEWAL_OFFER_FRAGMENT}
`;

export default ONBOARDING_LEASE_FRAGMENT;
