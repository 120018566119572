import type { UnitFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

import normalizeAddress from './Address';
import normalizeGeolocatedAddress from './GeolocatedAddress';
import normalizeMedia from './Media';

export default function normalizeUnit(input: WithoutNulls<UnitFragment>) {
  return {
    id: input.id,
    address: normalizeAddress(input.address),
    beds: input.beds,
    baths: input.baths,
    name: input.name,
    coverImageId: input?.coverImage
      ? `/${input.coverImage.publicId}`
      : undefined,
    media: input.media.map(normalizeMedia),
    community: {
      id: input.community.id,
      name: input.community.name,
      address: normalizeGeolocatedAddress(input.community.address),
      propertyManager: input.community.propertyManager.name,
      liabilityCoverage: input.community.liabilityCoverage,
    },
  };
}

export type Unit = ReturnType<typeof normalizeUnit>;
