import MenuLink from '$/components/Molecules/MenuLink';
import { PricingType } from '$/graphql/generated';
import useMenuHandler from '$/hooks/useMenuHandler';
import { memo } from 'react';

import * as Styled from './styles';
import { Props } from './types';

function MenuDesktop({
  anchorEl,
  className,
  handleLogout,
  handleOnClose,
  handleReset,
  open = false,
  pathname,
  hasReset,
}: Props): JSX.Element {
  const { handleUrl, handleMailTo } = useMenuHandler({
    handleOnClose,
    pathname,
  });

  return (
    <Styled.Popper
      open={open}
      id="popper-menu-desktop"
      placement="bottom-end"
      anchorEl={anchorEl}
    >
      <Styled.Container className={className}>
        <Styled.Links>
          <MenuLink
            Icon={Styled.UserIcon}
            isActive={pathname === '/profile'}
            label="Your profile"
            onClick={() => handleUrl('/profile')}
          />
          <MenuLink
            Icon={Styled.LeaseIcon}
            isActive={pathname === '/lease'}
            label="Your Lease"
            onClick={() => handleUrl('/lease')}
          />
          <MenuLink
            // href="mailto:residentsupport@heyrenew.com"
            Icon={Styled.ChatIcon}
            label="Contact us"
            onClick={() => handleMailTo('mailto:residentsupport@heyrenew.com')}
          />
          <MenuLink
            Icon={Styled.FileIcon}
            label="Terms of Use"
            target="_blank"
            href="https://heyrenew.com/terms-of-use"
          />
          {hasReset && (
            <>
              <MenuLink
                Icon={Styled.RefreshIcon}
                label="Reset"
                onClick={() => handleReset(PricingType.Standard)}
              />
              <MenuLink
                Icon={Styled.RefreshIcon}
                label="Reset Dynamic"
                onClick={() => handleReset(PricingType.Dynamic)}
              />
              <MenuLink
                Icon={Styled.RefreshIcon}
                label="Reset Without Rewards"
                withArrow
                onClick={() => handleReset(PricingType.Standard, false)}
              />
            </>
          )}
          <MenuLink
            Icon={Styled.LogoutIcon}
            isRed
            label="Logout"
            onClick={handleLogout}
          />
        </Styled.Links>
      </Styled.Container>
    </Styled.Popper>
  );
}

export default memo(MenuDesktop);
