import MenuDesktop from '$/components/Organisms/MenuDesktop';

import useConnect from './connect';
import * as Styled from './styles';
import type { Props } from './types';

export default function NavbarDesktop({
  className,
  handleBack,
  text,
  noBottomShadow = false,
}: Props): JSX.Element {
  const {
    handleCloseMenu,
    handleLogout,
    handleToggleMenu,
    handleReset,
    anchorEl,
    openMenu,
    pathname,
    hasReset,
  } = useConnect();

  return (
    <div className={className}>
      <Styled.Bar
        openMenu={handleToggleMenu}
        open={openMenu}
        handleBack={handleBack}
        text={text}
        noBottomShadow={noBottomShadow}
      />

      <MenuDesktop
        anchorEl={anchorEl}
        handleLogout={handleLogout}
        handleOnClose={handleCloseMenu}
        handleReset={handleReset}
        open={openMenu}
        pathname={pathname}
        hasReset={hasReset}
      />
    </div>
  );
}
