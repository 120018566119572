import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Content = styled.main<{ $centerContent?: boolean }>`
  height: 100%;

  ${({ $centerContent }) =>
    $centerContent &&
    css`
      display: flex;
      justify-content: center;
    `}
`;
