import { loadAccessToken } from '$/graphql/storage/tokens';

export default function useLoggedInStatus(): 'logged-in' | 'logged-out' {
  let localAccessToken;

  if (typeof window !== 'undefined') {
    localAccessToken = loadAccessToken();
  }

  if (localAccessToken) {
    return 'logged-in';
  }

  return 'logged-out';
}
