import type { AddressFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GEOLOCATED_ADDRESS_FRAGMENT: TypedDocumentNode<AddressFragment> = gql`
  fragment GeolocatedAddress on Address {
    city
    secondary
    state
    street
    zipCode
    latitude
    longitude
    id
  }
`;

export default GEOLOCATED_ADDRESS_FRAGMENT;
