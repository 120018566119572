import { AnalyticsBrowser } from '@segment/analytics-next';

const analytics = new AnalyticsBrowser();

if (typeof window !== 'undefined') {
  if (document?.cookie?.indexOf('renew_skip_analytics') === -1) {
    analytics.load(
      {
        writeKey: process.env['NEXT_PUBLIC_ANALYTICS_WRITE_KEY'] || '',
        cdnURL: 'https://s-cdn.heyrenew.com',
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: 's-t.heyrenew.com/v1',
            protocol: 'https',
          },
        },
      },
    );
  }
}

export default analytics;
