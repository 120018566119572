import type { LocationFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

import { normalizeGeolocationType } from './GeolocationType';

export default function normalizeLocation(
  input: WithoutNulls<LocationFragment>,
) {
  return {
    latitude: input.latitude,
    longitude: input.longitude,
    name: input.name,
    type: input.type ? normalizeGeolocationType(input.type) : undefined,
  };
}

export type Location = ReturnType<typeof normalizeLocation>;
