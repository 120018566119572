import Logo from '$/assets/images/renew-logo.svg';
import { memo } from 'react';

import * as Styled from './styles';
import { Props } from './types';

function TopBar({
  className,
  handleBack,
  text,
  withoutBorder,
  hideUser = false,
  openMenu,
}: Props): JSX.Element {
  return (
    <Styled.Bar $withoutBorder={withoutBorder} className={className}>
      <Styled.Left>
        {handleBack && (
          <>
            <Styled.Button onClick={handleBack}>
              <Styled.ArrowLeftIcon />
            </Styled.Button>
            <Styled.Divider />
          </>
        )}
        <Styled.Button onClick={() => openMenu()}>
          <Styled.MenuIcon />
        </Styled.Button>
      </Styled.Left>

      <Styled.Center>
        {text ? (
          <Styled.Text>{text}</Styled.Text>
        ) : (
          <Styled.LogoLink href="/" passHref>
            <Logo aria-label="Renew" />
          </Styled.LogoLink>
        )}
      </Styled.Center>

      {!hideUser && (
        <Styled.Right>
          <Styled.Link href="/profile" passHref>
            <Styled.UserIcon />
          </Styled.Link>
        </Styled.Right>
      )}
    </Styled.Bar>
  );
}

export default memo(TopBar);
