import type { UserSummaryFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeUserSummary(
  input: WithoutNulls<UserSummaryFragment>,
) {
  return {
    id: input.id,
    email: input.email,
    firstName: input.firstName,
    cellPhone: input.cellPhone,
    homePhone: input.homePhone,
    lastName: input.lastName,
    name: `${input.firstName} ${input.lastName}`,
    communityLogoId: input.communityLogoId,
    resident: input.resident,
    inviteToken: input.inviteToken,
  };
}

export type UserSummary = ReturnType<typeof normalizeUserSummary>;
