const ObjectWithFilter = ({
  object,
  filters,
}: {
  object: { [key: string]: string | string[] | undefined };
  filters: string[];
}): {
  [key: string]: string | string[] | undefined;
} => {
  const clonedObject = object;
  filters.map((filter) => delete clonedObject[filter]);
  return clonedObject;
};

export default ObjectWithFilter;
