import type { UserPreferenceFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import LOCATION_FRAGMENT from './Location';

const USER_PREFERENCES_FRAGMENT: TypedDocumentNode<UserPreferenceFragment> = gql`
  fragment UserPreference on UserPreference {
    location {
      ...Location
    }
    minBed
    minBath
    minBudget
    maxBudget
    amenities
  }
  ${LOCATION_FRAGMENT}
`;

export default USER_PREFERENCES_FRAGMENT;
