import useOnboardingUser from '$/graphql/hooks/onboarding/useOnboardingUser';
import useCurrentUser from '$/graphql/hooks/user/useCurrentUser';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

export default function SentryUserManager(): null {
  const { data: user } = useCurrentUser();
  const { data: onboardingUser } = useOnboardingUser();
  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({
        email: user.email,
        id: user.id,
      });
    } else if (onboardingUser?.id) {
      Sentry.setUser({
        id: onboardingUser.id,
      });
    }
  }, [user, onboardingUser]);

  return null;
}
