import type { RenewalOfferDecisionFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import RENEWAL_OFFER_PRICING_FRAGMENT from './RenewalOfferPricing';
import RENEWAL_OFFER_TERM_FRAGMENT from './RenewalOfferTerm';
import USER_SUMMARY_FRAGMENT from './UserSummary';

const RENEWAL_OFFER_DECISION_FRAGMENT: TypedDocumentNode<RenewalOfferDecisionFragment> = gql`
  fragment RenewalOfferDecision on RenewalOfferDecision {
    id
    decidedByUser {
      ...UserSummary
    }
    decidedAt
    decision
    interestedTerm {
      ...RenewalOfferPricingTerm
    }
    pricing {
      ...RenewalOfferPricing
    }
    eSignature {
      id
      status
      signedUsers {
        id
      }
    }
  }
  ${RENEWAL_OFFER_PRICING_FRAGMENT}
  ${RENEWAL_OFFER_TERM_FRAGMENT}
  ${USER_SUMMARY_FRAGMENT}
`;

export default RENEWAL_OFFER_DECISION_FRAGMENT;
