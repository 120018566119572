/*
Use this React component as the parent for any child components that should not be run
server-side in NextJS/Vercel (which includes any GraphQL calls dependent on URL params
or local storage).
https://nextjs.org/docs/basic-features/pages#two-forms-of-pre-rendering:
-- NextJS default is to attempt to "hydrate" the DOM server-side opportunistically,
including at first request build-time. Server-side, NextJS ignores any URL params.
https://www.apollographql.com/blog/apollo-client/next-js/next-js-getting-started/
https://www.joshwcomeau.com/react/the-perils-of-rehydration/

2021-01-04: For lack of a better place right now outside of a larger refactor, also
adding logic here to not render children components until the "init" apollo reactiveVar
is set to true, which Z1 implemented to set to true only after apollo first determines
whether there are access tokens in local storage or not.
*/

import initVar from '$/graphql/variables/init';
import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';

import type { Props } from './types';

function ClientRenderOnly({
  children,
  ...delegated
}: Props): JSX.Element | null {
  const [hasMounted, setHasMounted] = useState(false);
  const init = useReactiveVar(initVar); // init is false until apollo first determines logged-in state

  useEffect(() => {
    setHasMounted(true);
  }, []); // nextjs default server side initial html generation won't run this hook

  if (!hasMounted || !init) {
    return null;
  }
  return <div {...delegated}>{children}</div>;
}

export default ClientRenderOnly;
