import { GeolocationType as BackendGeolocationType } from '$/graphql/generated';

export type GeolocationType = 'locality' | 'neighborhood' | 'sublocality';

export function denormalizeGeolocationType(
  input: GeolocationType,
): BackendGeolocationType {
  switch (input) {
    default:
    case 'locality':
      return BackendGeolocationType.Locality;
    case 'neighborhood':
      return BackendGeolocationType.Neighborhood;
    case 'sublocality':
      return BackendGeolocationType.Sublocality;
  }
}

export function normalizeGeolocationType(input: string): GeolocationType {
  switch (input) {
    default:
    case 'locality':
      return 'locality';
    case 'sublocality':
      return 'sublocality';
    case 'neighborhood':
      return 'neighborhood';
  }
}
