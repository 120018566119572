import type { GeolocatedAddressFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeGeolocatedAddress(
  input: WithoutNulls<GeolocatedAddressFragment>,
) {
  return {
    city: input.city,
    secondary: input.secondary,
    state: input.state,
    street: input.street,
    zipCode: input.zipCode,
    latitude: input.latitude,
    longitude: input.longitude,
  };
}

export type GeolocatedAddress = ReturnType<typeof normalizeGeolocatedAddress>;
