import ConditionalWrapper from '$/components/Atoms/ConditionalWrapper';
import RouterLink from 'next/link';
import { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

function MenuLink({
  href,
  target,
  Icon,
  label,
  isActive = false,
  isRed = false,
  onClick,
  withArrow = false,
}: Props): JSX.Element {
  return (
    <ConditionalWrapper
      condition={!!href}
      wrapper={(children) => (
        <RouterLink
          href={href ?? ''}
          passHref
          target={target}
          style={{ textDecoration: 'none' }}
        >
          {children}
        </RouterLink>
      )}
    >
      <Styled.Container $isRed={isRed} $isActive={isActive} onClick={onClick}>
        <Styled.InnerContainer>
          <Icon />
          <Styled.Text>{label}</Styled.Text>
        </Styled.InnerContainer>
        {withArrow && <Styled.ArrowIcon />}
      </Styled.Container>
    </ConditionalWrapper>
  );
}

export default memo(MenuLink);
