import TopBarOnboarding from '$/components/Organisms/TopBarOnboarding';
import TopBarOnboardingDesktop from '$/components/Organisms/TopBarOnboardingDesktop';

import useConnect from './connect';
import * as Styled from './styles';
import type { Props } from './types';

/**
 * This layout is used for non-authenticated sessions.
 */
export default function GuestLayout({
  centerContent,
  children,
  className,
  topBarHandleLeft,
  topBarHandleRight,
  topBarText,
  topBarWithoutBorder,
}: Props): JSX.Element {
  const { isDesktop } = useConnect();

  return (
    <Styled.Container className={className}>
      {isDesktop ? (
        <TopBarOnboardingDesktop
          handleBack={topBarHandleLeft}
          handleInfo={topBarHandleRight}
          text={topBarText}
        />
      ) : (
        <TopBarOnboarding
          handleBack={topBarHandleLeft}
          handleInfo={topBarHandleRight}
          text={topBarText}
          withoutBorder={topBarWithoutBorder}
        />
      )}

      <Styled.Content $centerContent={centerContent}>{children}</Styled.Content>
    </Styled.Container>
  );
}
