import setupClient from '$/graphql/client';
import link from '$/graphql/statusNotifier';
import globalAlternateStates from '$/graphql/variables/error';
import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';

export default function useConnect() {
  const alternateState = useReactiveVar(globalAlternateStates);
  const client = useMemo(() => setupClient({ statusNotifierLink: link }), []);

  return { client, showError: alternateState.showError };
}
