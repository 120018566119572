import { from } from '$/styles/responsive';
import { useMediaQuery } from '@mui/material';

import { useLoggedInRedirect } from './logic';

export default function useConnect() {
  const isDesktop = useMediaQuery(from.tabletLandscape.query, { noSsr: true });
  useLoggedInRedirect();

  return {
    isDesktop,
  };
}
