import useRefreshAccessToken from '$/graphql/hooks/auth/useRefreshAccessToken';
import {
  loadAccessToken,
  loadRefreshToken,
  removeAccessToken,
  removeRefreshToken,
} from '$/graphql/storage/tokens';
import initVar from '$/graphql/variables/init';
import { isPast } from 'date-fns';
import { subSeconds } from 'date-fns/fp';
import decodeJwt from 'jwt-decode';
import { useCallback, useEffect } from 'react';

export default function useInitialization() {
  const { refreshAccessTokenMutation } = useRefreshAccessToken();

  const handleRefreshToken = useCallback(async () => {
    const accessToken = loadAccessToken();
    const refreshToken = loadRefreshToken();

    if (accessToken && refreshToken) {
      // We multiply x1000 because the JWT contains the date in seconds but
      // date-fns wants milliseconds.
      const expiration = decodeJwt<{ exp: number }>(accessToken).exp * 1000;

      // If the token has expired or is about to (to protect against race
      // conditions), renew it.
      if (isPast(subSeconds(expiration, 10))) {
        try {
          await refreshAccessTokenMutation(refreshToken);
        } catch {
          removeAccessToken();
          removeRefreshToken();
        }
      }
    }

    initVar(true);
  }, [refreshAccessTokenMutation]);

  useEffect(() => {
    void handleRefreshToken();
  }, [handleRefreshToken, refreshAccessTokenMutation]);
}
