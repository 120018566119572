export type WithoutNulls<T> = T extends Record<string, unknown>
  ? {
      [TKey in keyof T]: WithoutNulls<T[TKey]>;
    }
  : T extends Array<unknown>
  ? Array<WithoutNulls<T[number]>>
  : T extends null
  ? undefined
  : T;

export function withoutNulls<T>(input: T): WithoutNulls<T> {
  if (input === null) {
    return undefined as unknown as WithoutNulls<T>;
  }

  if (typeof input === 'object') {
    if (Array.isArray(input)) {
      return input.map(withoutNulls) as unknown as WithoutNulls<T>;
    }

    return Object.entries(input).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: withoutNulls(value) as WithoutNulls<T>,
      }),
      {} as WithoutNulls<T>,
    );
  }

  return input as unknown as WithoutNulls<T>;
}
