import type { OnboardingUserFragment } from '$/graphql/generated';
import normalizeOnboardingLease from '$/model/OnboardingLease';
import type { WithoutNulls } from '$/utils/withoutNulls';

import normalizeIncentives from './Incentive';

export default function normalizeOnboardingUser(
  input: WithoutNulls<OnboardingUserFragment>,
) {
  return {
    id: input.id,
    claimed: input.claimed,
    firstName: input.firstName,
    inviteToken: input.inviteToken,
    maskedEmail: input.maskedEmail,
    maskedPhone: input.maskedPhone,
    experiments: input.experiments,
    incentives: normalizeIncentives(input.incentives),
    currentLease: normalizeOnboardingLease(input.currentLease),
    enabledFeatures: input.enabledFeatures,
  };
}

export type OnboardingUser = ReturnType<typeof normalizeOnboardingUser>;
