import { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

// This component is only used in TopBarDesktop
function Link({
  href,
  label,
  isActive = false,
  className,
  onClick,
}: Props): JSX.Element {
  return (
    <Styled.Link
      href={href}
      passHref
      $isActive={isActive}
      className={className}
      onClick={onClick}
    >
      <Styled.Text $size="lg">{label}</Styled.Text>
    </Styled.Link>
  );
}

export default memo(Link);
