import type { GetUserCommunityQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import CURRENT_COMMUNITY_FRAGMENT from '../../fragments/CurrentCommunity';

const GET_USER_COMMUNITY: TypedDocumentNode<GetUserCommunityQuery> = gql`
  query GetUserCommunity {
    currentUser {
      id
      currentLease {
        id
        community {
          ...CurrentCommunity
        }
      }
    }
  }

  ${CURRENT_COMMUNITY_FRAGMENT}
`;

export default GET_USER_COMMUNITY;
