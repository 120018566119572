/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import keysOf from '$/utils/keysOf';
import { BaseThemedCssFunction, css, DefaultTheme } from 'styled-components';

const size = {
  tabletPortrait: '600px',
  tabletLandscape: '900px',
  laptop: '1200px',
  desktop: '1800px',
} as const;

const minWidthQuery = (width: string) => `@media (min-width: ${width})`;

const minWidth =
  (width: string): BaseThemedCssFunction<DefaultTheme> =>
  (first: any, ...args: any) =>
    css`
      ${minWidthQuery(width)} {
        ${css(first, ...args)};
      }
    `;

const maxWidthQuery = (width: string) => `@media (max-width: ${width})`;

const maxWidth =
  (width: string): BaseThemedCssFunction<DefaultTheme> =>
  (first: any, ...args: any) =>
    css`
      ${maxWidthQuery(width)} {
        ${css(first, ...args)};
      }
    `;

interface Breakpoint extends ReturnType<typeof minWidth> {
  query: string;
}

export const from = keysOf(size).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (() => {
      const mixin = minWidth(size[key]) as Breakpoint;
      mixin.query = minWidthQuery(size[key]);
      return mixin;
    })(),
  }),
  {} as { [key in keyof typeof size]: Breakpoint },
);

export const till = keysOf(size).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (() => {
      const mixin = maxWidth(size[key]) as Breakpoint;
      mixin.query = maxWidthQuery(size[key]);
      return mixin;
    })(),
  }),
  {} as { [key in keyof typeof size]: Breakpoint },
);
