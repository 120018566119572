import DefaultButton from '$/components/Atoms/Button';
import addAlpha from '$/utils/addAlpha';
import { Drawer as DefaultDrawer } from '@mui/material';
import DefaultArchiveIcon from 'remixicon-react/ArchiveLineIcon';
import DefaultChatIcon from 'remixicon-react/Chat1LineIcon';
import DefaultCloseIcon from 'remixicon-react/CloseLineIcon';
import DefaultRenewalOfferIcon from 'remixicon-react/DoorLockLineIcon';
import DefaultDoorOpenIcon from 'remixicon-react/DoorOpenLineIcon';
import DefaultFavoritesIcon from 'remixicon-react/HeartLineIcon';
import DefaultHomeIcon from 'remixicon-react/HomeSmile2LineIcon';
import DefaultDollarIcon from 'remixicon-react/MoneyDollarCircleLineIcon';
import DefaultQuestionIcon from 'remixicon-react/QuestionLineIcon';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import DefaultBrowsePropertiesIcon from 'remixicon-react/Search2LineIcon';
import DefaultLeaseIcon from 'remixicon-react/UserLocationLineIcon';
import styled, { css } from 'styled-components';

export const Drawer = styled(DefaultDrawer)`
  & .MuiPaper-root {
    box-shadow: 0 0.125rem 0.5rem rgba(22, 22, 22, 0.1);
  }
  & .MuiBackdrop-root {
    background-color: ${({ theme }) =>
      addAlpha(theme.customTheme.colors.grey700, 0.25)};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.customTheme.colors.white};
  width: 18.625rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 1.35rem 1.35rem 0 0;
`;

const iconStyles = css`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  height: 1.5rem;
  width: 1.5rem;
`;

export const Links = styled.nav`
  margin-bottom: 2rem;
  width: 100%;
`;

export const BrowsePropertiesIcon = styled(DefaultBrowsePropertiesIcon)`
  ${iconStyles};
`;

export const RefreshIcon = styled(RefreshLineIcon)`
  ${iconStyles};
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  ${iconStyles};
`;

export const FavoritesIcon = styled(DefaultFavoritesIcon)`
  ${iconStyles};
`;

export const QuestionIcon = styled(DefaultQuestionIcon)`
  ${iconStyles};
`;

export const HomeIcon = styled(DefaultHomeIcon)`
  ${iconStyles};
`;

export const LeaseIcon = styled(DefaultLeaseIcon)`
  ${iconStyles};
`;

export const RenewalOfferIcon = styled(DefaultRenewalOfferIcon)`
  ${iconStyles};
`;

export const ArchiveIcon = styled(DefaultArchiveIcon)`
  ${iconStyles};
`;
export const ChatIcon = styled(DefaultChatIcon)`
  ${iconStyles};
`;
export const LogoutIcon = styled(DefaultDoorOpenIcon)`
  color: ${({ theme }) => theme.customTheme.colors.error600};
  height: 1.5rem;
  width: 1.5rem;
`;

export const DollarIcon = styled(DefaultDollarIcon)`
  ${iconStyles};
`;

export const ButtonLink = styled(DefaultButton)`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.customTheme.weights.semiBold};
  margin: auto 1.5rem 0;
  padding: 0 0 1.5rem;
  width: unset;
`;
