import type { ResidentFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const RESIDENT_FRAGMENT: TypedDocumentNode<ResidentFragment> = gql`
  fragment Resident on Resident {
    id
    firstName
    lastName
    emailAddress
    cellPhone
    status
  }
`;

export default RESIDENT_FRAGMENT;
