import { from } from '$/styles/responsive';
import { useMediaQuery } from '@mui/material';

import * as Styled from './styles';
import type { Props } from './types';
import MaxWidth from '../../Atoms/MaxWidth';
import SpaceBetween from '../../Atoms/SpaceBetween';

export default function StatusBars({
  className,
  sections,
  currentSection,
  positionAbsolute = true,
}: Props): JSX.Element {
  const isMobile = !useMediaQuery(from.tabletLandscape.query);
  return (
    <Styled.Container
      className={className}
      $positionAbsolute={positionAbsolute}
    >
      <Styled.SectionWrapper>
        {sections.map((section, i) => (
          <Styled.ProgressBar
            $width={section.width}
            $previous={currentSection > i}
            $current={currentSection === i}
            $next={currentSection < i}
            key={section.name}
          />
        ))}
      </Styled.SectionWrapper>
      <MaxWidth>
        <SpaceBetween>
          {!isMobile && (
            <Styled.PositionDefiner $size="xs" $weight="medium">
              Now: {sections[currentSection]?.name || ''}
            </Styled.PositionDefiner>
          )}
          <Styled.PositionDefiner $size="xs" $weight="medium">
            {sections[currentSection + 1] &&
              `Up next: ${sections[currentSection + 1]?.name || ''}`}
          </Styled.PositionDefiner>
        </SpaceBetween>
      </MaxWidth>
    </Styled.Container>
  );
}
