const accessTokenKey = 'accessToken_v1';
const refreshTokenKey = 'refreshToken_v1';

export const saveAccessToken = (accessToken: string): void => {
  localStorage.setItem(accessTokenKey, accessToken);
};

export const loadAccessToken = (): string | undefined =>
  localStorage.getItem(accessTokenKey) ?? undefined;

export const removeAccessToken = (): void => {
  localStorage.removeItem(accessTokenKey);
};

export const saveRefreshToken = (refreshToken: string): void => {
  localStorage.setItem(refreshTokenKey, refreshToken);
};

export const loadRefreshToken = (): string | undefined =>
  localStorage.getItem(refreshTokenKey) ?? undefined;

export const removeRefreshToken = (): void => {
  localStorage.removeItem(refreshTokenKey);
};
