import type { RenewalOfferPricingFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import RENEWAL_OFFER_TERM_FRAGMENT from './RenewalOfferTerm';

const RENEWAL_OFFER_PRICING_FRAGMENT: TypedDocumentNode<RenewalOfferPricingFragment> = gql`
  fragment RenewalOfferPricing on RenewalOfferPricing {
    id
    createdAt
    terms {
      ...RenewalOfferPricingTerm
    }
    effectiveFrom
    effectiveTo
  }
  ${RENEWAL_OFFER_TERM_FRAGMENT}
`;

export default RENEWAL_OFFER_PRICING_FRAGMENT;
