import type { CommunitySummaryFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const ONBOARDING_COMMUNITY_FRAGMENT: TypedDocumentNode<CommunitySummaryFragment> = gql`
  fragment OnboardingCommunity on Community {
    id
    name
    propertyManager {
      name
      id
    }
  }
`;

export default ONBOARDING_COMMUNITY_FRAGMENT;
