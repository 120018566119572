import type { UnitFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import ADDRESS_FRAGMENT from './Address';
import GEOLOCATED_ADDRESS_FRAGMENT from './GeolocatedAddress';
import MEDIA_FRAGMENT from './Media';

const UPGRADE_UNIT_FRAGMENT: TypedDocumentNode<UnitFragment> = gql`
  fragment UpgradeUnit on Unit {
    id
    name
    address {
      ...Address
    }
    beds
    baths
    media {
      ...Media
    }
    minSqft
    maxSqft
    rent
    securityDeposit
    pointValue
    availabilityDate
    community {
      id
      name
      propertyManager {
        name
      }
      address {
        ...GeolocatedAddress
      }
    }
    keyAmenities {
      onSiteLaundry
      garage
      patioBalcony
    }
    floorPlan {
      mapImageId
    }
  }
  ${GEOLOCATED_ADDRESS_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;

export default UPGRADE_UNIT_FRAGMENT;
