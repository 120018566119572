import type { OnboardingUserFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import INCENTIVE_FRAGMENT from './Incentive';
import ONBOARDING_LEASE_FRAGMENT from './OnboardingLease';
import USER_EXPERIMENT_FRAGMENT from './UserExperiment';

const ONBOARDING_USER_FRAGMENT: TypedDocumentNode<OnboardingUserFragment> = gql`
  fragment OnboardingUser on OnboardingUser {
    id
    claimed
    firstName
    inviteToken
    maskedEmail
    maskedPhone
    experiments {
      ...UserExperiment
    }
    incentives {
      ...Incentive
    }
    currentLease {
      ...OnboardingLease
    }
    enabledFeatures
  }
  ${ONBOARDING_LEASE_FRAGMENT}
  ${INCENTIVE_FRAGMENT}
  ${USER_EXPERIMENT_FRAGMENT}
`;

export default ONBOARDING_USER_FRAGMENT;
