import { UpgradeUnitStatus } from '$/graphql/generated';

export enum UpgradeStatus {
  INTERESTED = 'INTERESTED',
  OFFERED = 'OFFERED',
}

export const normalizeUpgradeStatus = (
  input: UpgradeUnitStatus,
): UpgradeStatus => {
  switch (input) {
    case UpgradeUnitStatus.Interested:
      return UpgradeStatus.INTERESTED;
    default:
    case UpgradeUnitStatus.Offered:
      return UpgradeStatus.OFFERED;
  }
};
