import { LeaseStatus } from '$/graphql/generated';

export enum CurrentLeaseStatus {
  NEW = 'NEW',
  RENEWAL_OFFERED = 'RENEWAL_OFFERED',
  RENEWED = 'RENEWED',
  EXPIRED = 'EXPIRED',
}

export const normalizeLeaseStatus = (
  input: LeaseStatus,
): CurrentLeaseStatus => {
  switch (input) {
    case LeaseStatus.New:
      return CurrentLeaseStatus.NEW;
    case LeaseStatus.Renewed:
      return CurrentLeaseStatus.RENEWED;
    case LeaseStatus.Expired:
      return CurrentLeaseStatus.EXPIRED;
    // default to renewal offered for now (this shouldn't happen with the required graphql type)
    default:
    case LeaseStatus.RenewalOffered:
      return CurrentLeaseStatus.RENEWAL_OFFERED;
  }
};
