import styled, { css } from 'styled-components';

export const Container = styled.div<{ $isActive: boolean }>`
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.customTheme.colors.black};
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.customTheme.colors.white};
      background-color: ${({ theme }) => theme.customTheme.colors.night400};
    `};

  :hover {
    color: ${({ theme }) => theme.customTheme.colors.white};
    background-color: ${({ theme }) => theme.customTheme.colors.night400};
  }
`;
