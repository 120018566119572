import { memo } from 'react';

import type { Props } from './types';

function ConditionalWrapper({
  children,
  condition,
  wrapper,
}: Props): JSX.Element {
  return condition ? wrapper(children) : children;
}

export default memo(ConditionalWrapper);
