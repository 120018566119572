/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_ONBOARDING_USER from '$/graphql/queries/onboarding/getOnboardingUser';
import normalizeOnboardingUser, {
  OnboardingUser,
} from '$/model/OnboardingUser';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

export default function useOnboardingUser() {
  const { query } = useRouter();
  const token = typeof query['token'] === 'string' ? query['token'] : '';
  const [getOnboardingUser, { data: raw, loading }] =
    useLazyQuery(GET_ONBOARDING_USER);
  const onboardingData = withoutNulls(raw?.onboardingUser);

  const normalized = useMemo((): OnboardingUser | void => {
    if (onboardingData?.__typename === 'OnboardingUser') {
      return normalizeOnboardingUser(onboardingData);
    }
    return undefined;
  }, [onboardingData]);

  useEffect(() => {
    if (token !== '') {
      getOnboardingUser({
        variables: {
          token,
        },
      });
    }
  }, [getOnboardingUser, token]);
  if (
    normalized &&
    (!localStorage['token'] ||
      localStorage.getItem('token') !== normalized?.inviteToken)
  ) {
    localStorage.setItem('token', normalized?.inviteToken);
  }
  return {
    data: normalized,
    alternateResponse:
      onboardingData?.__typename === 'UnknownOnboardingUser'
        ? onboardingData.message
        : undefined,
    loading,
  };
}
