import useLoggedInStatus from '$/graphql/hooks/auth/useLoggedInStatus';
import useOnboardingUser from '$/graphql/hooks/onboarding/useOnboardingUser';
import useUserCommunity from '$/graphql/hooks/user/useUserCommunity';
import useUserSummary from '$/graphql/hooks/user/useUserSummary';
import initVar from '$/graphql/variables/init';
import { useReactiveVar } from '@apollo/client';

export default function useConnect() {
  const { data: onboardingUser } = useOnboardingUser();
  const { data: userSummary } = useUserSummary();
  const { data: userCommunity } = useUserCommunity();

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time client side
  const status = useLoggedInStatus();
  const isAuthenticated = init && status === 'logged-in';
  return {
    onboardingUser,
    isAuthenticated,
    authCheckData: { user: userSummary, community: userCommunity },
  };
}
