import Menu from '$/components/Organisms/Menu';

import useConnect from './connect';
import * as Styled from './styles';
import type { Props } from './types';

export default function Navbar({
  className,
  handleBack,
  text,
  withoutBorder,
  hideUser,
}: Props): JSX.Element {
  const {
    handleCloseMenu,
    handleLogout,
    handleOpenMenu,
    handleReset,
    openMenu,
    pathname,
    hasReset,
  } = useConnect();

  return (
    <div className={className}>
      <Styled.Bar
        openMenu={handleOpenMenu}
        handleBack={handleBack}
        text={text}
        withoutBorder={withoutBorder}
        hideUser={hideUser}
      />

      <Menu
        handleLogout={handleLogout}
        handleOnClose={handleCloseMenu}
        handleReset={handleReset}
        open={openMenu}
        pathname={pathname}
        hasReset={hasReset}
      />
    </div>
  );
}
