import type { OnboardingUnitFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeOnboardingUnit(
  input: WithoutNulls<OnboardingUnitFragment>,
) {
  return {
    beds: input.beds,
    baths: input.baths,
    name: input.name,
    community: input.community,
  };
}

export type OnboardingUnit = ReturnType<typeof normalizeOnboardingUnit>;
