import type {
  UpdateMoveOutInfoMutation,
  UpdateMoveOutInfoMutationVariables,
} from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import Move_OUT_INFO_FRAGMENT from '../../fragments/MoveOutInfo';

const UPDATE_MOVE_OUT_INFO_MUTATION: TypedDocumentNode<
  UpdateMoveOutInfoMutation,
  UpdateMoveOutInfoMutationVariables
> = gql`
  mutation UpdateMoveOutInfo(
    $forwardingAddress: ForwardingAddressInput
    $reasonForMoveOut: FormSingleSelectInput!
    $noticeToVacateFlow: Boolean!
    $moveOutDate: Datetime
  ) {
    updateMoveOutInfo(
      input: {
        forwardingAddress: $forwardingAddress
        reasonForMoveOut: $reasonForMoveOut
        noticeToVacateFlow: $noticeToVacateFlow
        moveOutDate: $moveOutDate
      }
    ) {
      ... on MoveOutInfo {
        ...MoveOutInfo
      }
    }
  }
  ${Move_OUT_INFO_FRAGMENT}
`;

export default UPDATE_MOVE_OUT_INFO_MUTATION;
