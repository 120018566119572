import RouterLink from 'next/link';
import { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

function IconLink({
  icon,
  href,
  isActive = false,
  className,
}: Props): JSX.Element {
  return (
    <RouterLink href={href} passHref>
      <Styled.Container $isActive={isActive} className={className}>
        {icon}
      </Styled.Container>
    </RouterLink>
  );
}

export default memo(IconLink);
