import { from } from '$/styles/responsive';
import styled, { css } from 'styled-components';

export const Footer = styled.div<{ $fixedButtonPosition?: boolean }>`
  margin: 0px auto;
  padding: ${({ theme }) =>
    ` ${theme.customTheme.spacing.xs} ${theme.customTheme.spacing.md}`};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${({ theme }) => theme.customTheme.colors.night900};
  z-index: 2;

  ${({ $fixedButtonPosition }) =>
    $fixedButtonPosition &&
    css`
      bottom: 0px;
      position: fixed;
    `}

  ${from.tabletLandscape`
    position: relative;
    bottom: auto;
  `};
`;
