import { Popper as DefaultPopper } from '@mui/material';
import DefaultArchiveIcon from 'remixicon-react/ArchiveLineIcon';
import DefaultChatIcon from 'remixicon-react/Chat1LineIcon';
import DefaultDoorOpenIcon from 'remixicon-react/DoorOpenLineIcon';
import RefreshLineIcon from 'remixicon-react/RefreshLineIcon';
import NoteIcon from 'remixicon-react/StickyNoteLineIcon';
import DefaultUserIcon from 'remixicon-react/UserLineIcon';
import DefaultLeaseIcon from 'remixicon-react/UserLocationLineIcon';
import styled, { css } from 'styled-components';

export const Popper = styled(DefaultPopper)`
  box-shadow: 0 0.125rem 0.5rem rgba(22, 22, 22, 0.1);
  border-radius: 1.25rem;
  overflow: hidden;
  top: 1.75rem !important;
  z-index: ${({ theme }) => theme.customTheme.zIndex.menu};
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.customTheme.colors.white};

  width: 18.625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const iconStyles = css`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  height: 1.5rem;
  width: 1.5rem;
`;

export const Links = styled.nav`
  width: 100%;
`;

export const UserIcon = styled(DefaultUserIcon)`
  ${iconStyles};
`;

export const RefreshIcon = styled(RefreshLineIcon)`
  ${iconStyles};
`;

export const FileIcon = styled(NoteIcon)`
  ${iconStyles};
`;

export const LeaseIcon = styled(DefaultLeaseIcon)`
  ${iconStyles};
`;

export const ArchiveIcon = styled(DefaultArchiveIcon)`
  ${iconStyles};
`;
export const ChatIcon = styled(DefaultChatIcon)`
  ${iconStyles};
`;
export const LogoutIcon = styled(DefaultDoorOpenIcon)`
  color: ${({ theme }) => theme.customTheme.colors.error600};
  height: 1.5rem;
  width: 1.5rem;
`;
