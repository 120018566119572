import defaultTheme from '$/styles/themes';
import { css, FlattenSimpleInterpolation } from 'styled-components';

import { from } from './responsive';

type Theme = typeof defaultTheme.light.customTheme.spacingV2.desktop;
type Spacing = keyof Theme;
type OptionArray = {
  size: Spacing | '0' | 'auto';
  direction: number[]; // 0,1,2,3
}[];
type SpecificOption = {
  size: Spacing;
  direction: 'left' | 'right' | 'top' | 'bottom' | 'vertical' | 'horizontal';
};
const UtilizeSpacer = ({
  options,
  specificOption,
  type = 'margin',
}: {
  options?: OptionArray;
  specificOption?: SpecificOption;
  type: 'margin' | 'padding';
}): FlattenSimpleInterpolation => {
  const spacingSetUp = [''];
  const spacingSetUpMobile = [''];
  if (options) {
    options.forEach((option) => {
      option.direction.forEach((direction) => {
        spacingSetUp[direction] =
          option.size === '0' || option.size === 'auto'
            ? option.size
            : defaultTheme.light.customTheme.spacingV2.desktop[option.size];
        spacingSetUpMobile[direction] =
          option.size === '0' || option.size === 'auto'
            ? option.size
            : defaultTheme.light.customTheme.spacingV2.mobile[option.size];
      });
    });
  }
  const desktopStyle: string = spacingSetUp.join(' ');
  const mobileStyle: string = spacingSetUpMobile.join(' ');
  const desktopStyleSpecific: string | undefined =
    specificOption &&
    defaultTheme.light.customTheme.spacingV2.desktop[specificOption.size];
  const mobileStyleSpecific: string | undefined =
    specificOption &&
    defaultTheme.light.customTheme.spacingV2.mobile[specificOption.size];

  if (
    type === 'margin' &&
    specificOption &&
    specificOption.direction &&
    mobileStyleSpecific &&
    desktopStyleSpecific
  ) {
    return css`
      margin-top: ${specificOption.direction === 'top'
        ? mobileStyleSpecific
        : null};
      margin-right: ${specificOption.direction === 'right'
        ? mobileStyleSpecific
        : null};
      margin-bottom: ${specificOption.direction === 'bottom'
        ? mobileStyleSpecific
        : null};
      margin-left: ${specificOption.direction === 'left'
        ? mobileStyleSpecific
        : null};
      margin: ${specificOption.direction === 'vertical'
        ? `${mobileStyleSpecific} 0`
        : null};
      margin: ${specificOption.direction === 'horizontal'
        ? `0 ${mobileStyleSpecific}`
        : null};

      ${from.tabletLandscape`
          margin-top: ${
            specificOption.direction === 'top' ? desktopStyleSpecific : null
          };
          margin-right: ${
            specificOption.direction === 'right' ? desktopStyleSpecific : null
          };
          margin-bottom: ${
            specificOption.direction === 'bottom' ? desktopStyleSpecific : null
          };
          margin-left: ${
            specificOption.direction === 'left' ? desktopStyleSpecific : null
          };
          margin: ${
            specificOption.direction === 'vertical'
              ? `${desktopStyleSpecific} 0`
              : null
          };
          margin: ${
            specificOption.direction === 'horizontal'
              ? `0 ${desktopStyleSpecific}`
              : null
          };
      `}
    `;
  }

  if (
    type === 'padding' &&
    specificOption &&
    specificOption.direction &&
    mobileStyleSpecific &&
    desktopStyleSpecific
  ) {
    return css`
      padding-top: ${specificOption.direction === 'top'
        ? mobileStyleSpecific
        : null};
      padding-right: ${specificOption.direction === 'right'
        ? mobileStyleSpecific
        : null};
      padding-bottom: ${specificOption.direction === 'bottom'
        ? mobileStyleSpecific
        : null};
      padding-left: ${specificOption.direction === 'left'
        ? mobileStyleSpecific
        : null};
      padding: ${specificOption.direction === 'vertical'
        ? `${mobileStyleSpecific} 0`
        : null};
      padding: ${specificOption.direction === 'horizontal'
        ? `0 ${mobileStyleSpecific}`
        : null};

      ${from.tabletLandscape`
          padding-top: ${
            specificOption.direction === 'top' ? desktopStyleSpecific : null
          };
          padding-right: ${
            specificOption.direction === 'right' ? desktopStyleSpecific : null
          };
          padding-bottom: ${
            specificOption.direction === 'bottom' ? desktopStyleSpecific : null
          };
          padding-left: ${
            specificOption.direction === 'left' ? desktopStyleSpecific : null
          };
          padding: ${
            specificOption.direction === 'vertical'
              ? `${desktopStyleSpecific} 0`
              : null
          };
          padding: ${
            specificOption.direction === 'horizontal'
              ? `0 ${desktopStyleSpecific}`
              : null
          };
      `}
    `;
  }
  return type === 'padding'
    ? css`
        padding: ${mobileStyle};
        ${from.tabletLandscape`
                  padding: ${desktopStyle};
              `}
      `
    : css`
        margin: ${mobileStyle};
        ${from.tabletLandscape`
                  margin: ${desktopStyle};
              `}
      `;
};

export default UtilizeSpacer;
