import type {
  DeclineRenewalOfferMutation,
  DeclineRenewalOfferMutationVariables,
} from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import RENEWAL_OFFER_FRAGMENT from '../../fragments/RenewalOffer';

const DECLINE_RENEWAL_OFFER_MUTATION: TypedDocumentNode<
  DeclineRenewalOfferMutation,
  DeclineRenewalOfferMutationVariables
> = gql`
  mutation DeclineRenewalOffer(
    $forwardingAddress: ForwardingAddressInput
    $moveOutDate: Datetime
    $reasonForMoveOut: FormSingleSelectInput!
    $noticeToVacateFlow: Boolean!
  ) {
    declineRenewalOffer(
      input: {
        forwardingAddress: $forwardingAddress
        reasonForMoveOut: $reasonForMoveOut
        moveOutDate: $moveOutDate
        noticeToVacateFlow: $noticeToVacateFlow
      }
    ) {
      ... on RenewalOffer {
        ...RenewalOffer
      }
      ... on RenewalOfferDecisionExpiredNonExistent {
        __typename
        message
      }
      ... on RenewalOfferDecisionMade {
        __typename
        message
      }
    }
  }
  ${RENEWAL_OFFER_FRAGMENT}
`;

export default DECLINE_RENEWAL_OFFER_MUTATION;
