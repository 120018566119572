import RENEWAL_DATA_FRAGMENT from '$/graphql/fragments/RenewalData';
import type {
  GetRenewalDataQuery,
  GetRenewalDataQueryVariables,
} from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_RENEWAL_DATA: TypedDocumentNode<
  GetRenewalDataQuery,
  GetRenewalDataQueryVariables
> = gql`
  query GetRenewalData {
    currentUser {
      id
      ...RenewalData
    }
  }

  ${RENEWAL_DATA_FRAGMENT}
`;

export default GET_RENEWAL_DATA;
