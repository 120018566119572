import {
  removeAccessToken,
  removeRefreshToken,
} from '$/graphql/storage/tokens';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export default function useLogout() {
  const { push } = useRouter();
  const handleLogout = useCallback(() => {
    removeAccessToken();
    removeRefreshToken();
    void push('/logout');
  }, [push]);

  return { handleLogout };
}
