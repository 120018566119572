import type { CommunitySummaryFragment } from '$/graphql/generated';
import unreachable from '$/utils/unreachable';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeGeolocatedAddress from './GeolocatedAddress';

export default function normalizeCommunitySummary(
  input: WithoutNulls<CommunitySummaryFragment>,
) {
  const address = normalizeGeolocatedAddress(input.address);

  return {
    id: input.id,
    name: input.name,
    propertyManager: input.propertyManager.name,
    address,
    coverImageId: input?.coverImage
      ? `/${input.coverImage.publicId}`
      : undefined,
    rentRange: {
      min: input.rentRange?.min ?? unreachable(0),
      max: input.rentRange?.max ?? unreachable(0),
    },
    bathRange: {
      min: input.bathRange?.min ?? unreachable(0),
      max: input.bathRange?.max ?? unreachable(0),
    },
    bedsRange: {
      min: input.bedsRange?.min ?? unreachable(0),
      max: input.bedsRange?.max ?? unreachable(0),
    },
    pointValue: input.pointValue / 100,
    availabilityDate: input.availabilityDate
      ? parseISO(input.availabilityDate).getTime()
      : undefined,
    tourLink: input.tourLink ? input.tourLink : undefined,
  };
}

export type CommunitySummary = ReturnType<typeof normalizeCommunitySummary>;
