import { memo } from 'react';

import * as Styled from './styles';
import { Props } from './types';

function TopBarOnboardingDesktop({
  className,
  handleBack,
  handleInfo,
  text,
}: Props): JSX.Element {
  return (
    <>
      <Styled.Bar className={className}>
        <Styled.Content>
          <Styled.Left>
            <Styled.Logo aria-label="Renew" />
          </Styled.Left>
        </Styled.Content>
      </Styled.Bar>
      {text && (
        <Styled.SubBar>
          <Styled.Content>
            {handleBack && (
              <Styled.Left>
                <Styled.Button onClick={handleBack}>
                  <Styled.ArrowLeftIcon />
                </Styled.Button>
              </Styled.Left>
            )}
            <Styled.Center>
              <Styled.Text $size="lg" $weight="medium">
                {text}
              </Styled.Text>
            </Styled.Center>
            {handleInfo && (
              <Styled.Right>
                <Styled.Button onClick={handleInfo}>
                  <Styled.InformationIcon />
                </Styled.Button>
              </Styled.Right>
            )}
          </Styled.Content>
        </Styled.SubBar>
      )}
    </>
  );
}

export default memo(TopBarOnboardingDesktop);
