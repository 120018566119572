import addAlpha from '$/utils/addAlpha';
import RouterLink from 'next/link';
import styled, { css } from 'styled-components';

import loading from './animations';
import type { $ContainerProps } from './types';

export const Container = styled.button<$ContainerProps>`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: ${({ theme }) => theme.customTheme.weights.medium};
  justify-content: center;
  min-height: 3rem;
  width: 100%;
  transition: all 200ms ease;

  :disabled {
    cursor: not-allowed;
  }

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          border-radius: 0.5rem;
          font-size: 0.875rem;
          line-height: 1.25rem;
          padding: ${({ theme }) =>
            `${theme.customTheme.spacing.xs} ${theme.customTheme.spacing.sm}`};
        `;
      case 'big':
      default:
        return css`
          border-radius: 0.75rem;
          font-size: 0.9375rem;
          line-height: 1.3rem;
          padding: ${({ theme }) =>
            `${theme.customTheme.spacing.sm} ${theme.customTheme.spacing.lg}`};
        `;
    }
  }}

  ${({ theme, $variant }) => {
    switch ($variant) {
      case 'secondary':
        return css`
          background-color: transparent;
          border: 0.125rem solid ${theme.customTheme.colors.night400};
          color: ${theme.customTheme.colors.night400};

          &:hover:not(:disabled) {
            border-color: ${theme.customTheme.colors.night900};
            color: ${theme.customTheme.colors.night900};
          }

          &:focus:not(:disabled) {
            border-color: ${theme.customTheme.colors.cobalt400};
            color: ${theme.customTheme.colors.cobalt400};
          }

          &:disabled {
            border-color: ${addAlpha(theme.customTheme.colors.night400, 0.5)};
            color: ${addAlpha(theme.customTheme.colors.night400, 0.5)};
          }
        `;

      case 'disruptive':
        return css`
          background-color: transparent;
          border: 0.125rem solid ${theme.customTheme.colors.error600};
          color: ${theme.customTheme.colors.error600};

          &:hover:not(:disabled) {
            border-color: ${theme.customTheme.colors.error900};
            color: ${theme.customTheme.colors.error900};
          }

          &:focus:not(:disabled) {
            border: 0.125rem solid ${theme.customTheme.colors.cobalt400};
            color: ${theme.customTheme.colors.error900};
          }

          &:disabled {
            border-color: ${addAlpha(theme.customTheme.colors.error600, 0.5)};
            color: ${addAlpha(theme.customTheme.colors.error600, 0.5)};
          }
        `;

      case 'floating':
        return css`
          background-color: transparent;
          border: 0.125rem solid transparent;
          color: ${theme.customTheme.colors.night400};
          text-decoration: underline;
          padding: 0;

          &:hover:not(:disabled) {
            color: ${theme.customTheme.colors.night900};
          }

          &:focus:not(:disabled) {
            color: ${theme.customTheme.colors.night400};
          }

          &:disabled {
            color: ${addAlpha(theme.customTheme.colors.night400, 0.5)};
          }
        `;

      case 'primary':
      default:
        return css`
          background-color: ${theme.customTheme.colors.night400};
          border: 0.125rem solid ${theme.customTheme.colors.night400};
          color: ${theme.customTheme.colors.white};

          &:hover:not(:disabled) {
            background-color: ${theme.customTheme.colors.night900};
            border-color: ${theme.customTheme.colors.night900};
          }

          &:focus:not(:disabled) {
            background-color: ${theme.customTheme.colors.cobalt900};
            border-color: ${theme.customTheme.colors.cobalt400};
          }

          &:disabled {
            background-color: ${addAlpha(
              theme.customTheme.colors.night400,
              0.5,
            )};
            border-color: transparent;
          }
        `;
    }
  }}
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
`;

export const LoaderDot = styled.div`
  animation: ${loading} 500ms linear infinite;
  background-color: currentColor;
  border-radius: 50%;
  bottom: 0.5rem;
  height: 0.375rem;
  position: absolute;
  width: 0.375rem;

  :nth-child(1) {
    left: -0.25rem;
  }

  :nth-child(2) {
    animation-delay: 150ms;
    left: 0.25rem;
  }
  :nth-child(3) {
    animation-delay: 250ms;
    left: 0.75rem;
  }
`;

export const LoaderIcon = styled.div`
  align-items: center;
  display: flex;
  height: 0.7rem;
  justify-content: space-between;
  position: relative;
  width: 1.125rem;
`;
