import { Caption } from '$/components/Atoms/Typography';
import styled, { css } from 'styled-components';

import DefaultNavbar from './Share/NavBar';
import DefaultNavbarDesktop from './Share/NavBarDesktop';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Content = styled.div<{
  $centerContent?: boolean;
  $statusPadding?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ $centerContent }) =>
    $centerContent &&
    css`
      display: flex;
      justify-content: center;
    `}

  ${({ $statusPadding }) =>
    $statusPadding &&
    css`
      padding-top: 33px;
      position: relative;
    `}
`;

export const Navbar = styled(DefaultNavbar)`
  z-index: ${({ theme }) => theme.customTheme.zIndex.navbar};
`;

export const NavbarDesktop = styled(DefaultNavbarDesktop)`
  z-index: ${({ theme }) => theme.customTheme.zIndex.navbar};
`;

export const Text = styled(Caption)`
  font-weight: ${({ theme }) => theme.customTheme.weights.regular};
  color: ${({ theme }) => theme.customTheme.colors.white};
`;
