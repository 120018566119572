import USER_FRAGMENT from '$/graphql/fragments/User';
import type {
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables,
} from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const REFRESH_ACCESS_TOKEN_MUTATION: TypedDocumentNode<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
> = gql`
  mutation RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(input: { refreshToken: $refreshToken }) {
      ... on AuthenticationPayload {
        __typename
        accessToken
        currentUser {
          ...User
        }
        refreshToken
      }
      ... on InvalidRefreshToken {
        __typename
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;

export default REFRESH_ACCESS_TOKEN_MUTATION;
