import USER_FRAGMENT from '$/graphql/fragments/User';
import type { GetUserQuery, GetUserQueryVariables } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_USER: TypedDocumentNode<GetUserQuery, GetUserQueryVariables> = gql`
  query GetUser {
    currentUser {
      ...User
    }
  }

  ${USER_FRAGMENT}
`;

export default GET_USER;
