import useLogout from '$/graphql/hooks/auth/useLogout';
import useUserSummary from '$/graphql/hooks/user/useUserSummary';
import { useRouter } from 'next/router';

import { useHandleMenu, useTermsModal } from './logic';
import { useResetOffer } from '../../logic';

export default function useConnect() {
  const { data: user } = useUserSummary();
  const { asPath: pathname } = useRouter();
  const { openMenu, handleCloseMenu, handleOpenMenu } = useHandleMenu();

  const { onCloseTermsModal, onOpenTermsModal, openTermsModal } =
    useTermsModal();
  const { handleLogout } = useLogout();
  const { handleReset } = useResetOffer();

  const hasReset = Boolean(
    process.env['NEXT_PUBLIC_ENV'] !== 'production' ||
      user?.email?.includes('@heyrenew.com'),
  );

  return {
    handleCloseMenu,
    handleLogout,
    handleReset,
    handleOpenMenu,
    openMenu,
    onCloseTermsModal,
    onOpenTermsModal,
    openTermsModal,
    pathname,
    hasReset,
  };
}
