import type { RenewalOfferPricingTermFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import CHARGE_FRAGMENT from './Charge';

const RENEWAL_OFFER_TERM_FRAGMENT: TypedDocumentNode<RenewalOfferPricingTermFragment> = gql`
  fragment RenewalOfferPricingTerm on RenewalOfferPricingTerm {
    id
    lengthInMonths
    baseRent
    securityDeposit
    pointValue
    leaseEndDate
    leaseStartDate
    totalMonthlyCharge
    charges {
      ...Charge
    }
  }
  ${CHARGE_FRAGMENT}
`;

export default RENEWAL_OFFER_TERM_FRAGMENT;
