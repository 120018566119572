import { memo } from 'react';

import * as Styled from './styles';
import { Props } from './types';

function TopBarOnboarding({
  className,
  handleBack,
  handleInfo,
  text,
  withoutBorder,
}: Props): JSX.Element {
  return (
    <Styled.Container className={className}>
      <Styled.Bar $withoutBorder={withoutBorder}>
        {handleBack && (
          <Styled.Left>
            <Styled.Button onClick={handleBack}>
              <Styled.ArrowLeftIcon />
            </Styled.Button>
          </Styled.Left>
        )}

        <Styled.Center>
          {text ? (
            <Styled.Text>{text}</Styled.Text>
          ) : (
            <Styled.Logo aria-label="Renew" />
          )}
        </Styled.Center>

        {handleInfo && (
          <Styled.Right>
            <Styled.Button onClick={handleInfo}>
              <Styled.InformationIcon />
            </Styled.Button>
          </Styled.Right>
        )}
      </Styled.Bar>
    </Styled.Container>
  );
}

export default memo(TopBarOnboarding);
