import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { MenuHandlerProps } from './types';

export default function useMenuHandler({
  handleOnClose,
  pathname,
}: MenuHandlerProps): {
  handleUrl: (url: string) => void;
  handleMailTo: (mailto: string) => void;
  handleAction: (onAction: () => void) => void;
} {
  const { replace } = useRouter();

  const handleUrl = useCallback(
    (url: string) => {
      if (pathname !== url) {
        void replace(url);
      }

      handleOnClose();
    },
    [handleOnClose, pathname, replace],
  );

  const handleMailTo = useCallback(
    (mailto: string) => {
      window.location.href = mailto;
      handleOnClose();
    },
    [handleOnClose],
  );

  const handleAction = useCallback(
    (onAction: () => void) => {
      onAction();
      handleOnClose();
    },
    [handleOnClose],
  );

  return { handleUrl, handleMailTo, handleAction };
}
