import USER_SUMMARY_FRAGMENT from '$/graphql/fragments/UserSummary';
import type { GetUserSummaryQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_USER_SUMMARY: TypedDocumentNode<GetUserSummaryQuery> = gql`
  query GetUserSummary {
    currentUser {
      ...UserSummary
    }
  }

  ${USER_SUMMARY_FRAGMENT}
`;

export default GET_USER_SUMMARY;
