import ONBOARDING_USER_FRAGMENT from '$/graphql/fragments/OnboardingUser';
import type {
  GetOnboardingUserQuery,
  GetOnboardingUserQueryVariables,
} from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_ONBOARDING_USER: TypedDocumentNode<
  GetOnboardingUserQuery,
  GetOnboardingUserQueryVariables
> = gql`
  query GetOnboardingUser($token: String!) {
    onboardingUser(token: $token) {
      ... on OnboardingUser {
        __typename
        ...OnboardingUser
      }

      ... on UnknownOnboardingUser {
        __typename
        message
      }
    }
  }

  ${ONBOARDING_USER_FRAGMENT}
`;

export default GET_ONBOARDING_USER;
