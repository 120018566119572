import { PricingType } from '$/graphql/generated';
import useLogout from '$/graphql/hooks/auth/useLogout';
import useRenewalOfferActions from '$/graphql/hooks/renewal/useRenewalOfferActions';
import deeplinkPathname from '$/graphql/variables/deeplink';
import globalAlternateStates from '$/graphql/variables/error';
import initVar from '$/graphql/variables/init';
import ObjectWithFilter from '$/utils/filterObject';
import { useReactiveVar } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

export function useHandleMenu() {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, [setOpenMenu]);

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true);
  }, [setOpenMenu]);

  return { openMenu, handleCloseMenu, handleOpenMenu };
}

export function useLoginRedirect(status: 'logged-out' | 'logged-in') {
  const { replace, query, pathname } = useRouter();
  const { token } = query;
  const init = useReactiveVar(initVar);
  const loggedOut = status === 'logged-out' && init;
  const loggedIn = status === 'logged-in' && init;
  const alternateState = useReactiveVar(globalAlternateStates);
  const { handleLogout } = useLogout();

  const urlParameters = ObjectWithFilter({ object: query, filters: ['token'] });
  if (alternateState.permissionDenied && loggedIn) {
    globalAlternateStates({
      permissionDenied: false,
    });
    handleLogout();
  }
  if (loggedIn && token) {
    void replace({
      pathname,
      query: urlParameters,
    });
  }

  if (alternateState.permissionDenied && loggedOut) {
    void replace({
      pathname: `/login`,
      query: {
        token: localStorage.getItem('token'),
      },
    });
  }
  if (loggedOut && !alternateState.permissionDenied) {
    if (token) {
      deeplinkPathname({ pathname });
      void replace({
        pathname: `/login`,
        query: {
          token,
        },
      });
    }
    void replace('/logout');
  }
}

export function useResetOffer() {
  const { resetOffers } = useRenewalOfferActions();

  const handleReset = async (type: PricingType, rewards?: boolean) => {
    await resetOffers({
      pricingType: type,
      rewards,
    });
    window.location.reload();
  };

  return { handleReset };
}
