import type { UnitFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import ADDRESS_FRAGMENT from './Address';
import GEOLOCATED_ADDRESS_FRAGMENT from './GeolocatedAddress';
import MEDIA_FRAGMENT from './Media';

const UNIT_FRAGMENT: TypedDocumentNode<UnitFragment> = gql`
  fragment Unit on Unit {
    id
    address {
      ...Address
    }
    beds
    baths
    coverImage {
      ...Media
    }
    media {
      ...Media
    }
    name
    community {
      id
      name
      address {
        ...GeolocatedAddress
      }
      propertyManager {
        name
      }
      liabilityCoverage
    }
  }
  ${GEOLOCATED_ADDRESS_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${MEDIA_FRAGMENT}
`;

export default UNIT_FRAGMENT;
