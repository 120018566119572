import MaxWidth from '$/components/Atoms/MaxWidth';
import { Text as DefaultText } from '$/components/Atoms/Typography';
import UtilizeSpacer from '$/styles/theme-styles';
import addAlpha from '$/utils/addAlpha';
import RouterLink from 'next/link';
import ArrowLeft from 'remixicon-react/ArrowLeftSLineIcon';
import MenuIconDefault from 'remixicon-react/MenuLineIcon';
import styled, { css } from 'styled-components';

import DefaultLink from './Link';

export const Bar = styled.div<{ $noBottomShadow?: boolean }>`
  background: ${({ theme }) => theme.customTheme.colors.white};
  min-height: 3rem;
  width: 100%;

  ${({ $noBottomShadow }) =>
    !$noBottomShadow &&
    css`
      box-shadow: inset 0rem -0.0625rem 0rem ${({ theme }) => addAlpha(theme.customTheme.colors.grey600, 0.4)};
    `}
`;

export const SubBar = styled(Bar)<{ $noBottomShadow?: boolean }>`
  padding: ${({ theme }) => theme.customTheme.spacing.base};
  ${({ $noBottomShadow }) =>
    $noBottomShadow &&
    css`
      box-shadow: none;
    `}
`;

export const Content = styled(MaxWidth)`
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.3fr;
  width: 100%;
`;

export const Button = styled.button<{ $open?: boolean }>`
  background: none;
  border: none;
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.customTheme.colors.black};
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 3rem;
  height: 3rem;

  :hover {
    color: ${({ theme }) => theme.customTheme.colors.white};
    background-color: ${({ theme }) => theme.customTheme.colors.night400};
  }

  ${({ $open }) =>
    $open &&
    css`
      color: ${({ theme }) => theme.customTheme.colors.white};
      background-color: ${({ theme }) => theme.customTheme.colors.night400};
    `}
`;

export const Center = styled.div`
  grid-column: 2;
  justify-self: center;
  align-self: center;
  display: flex;

  * {
    ${UtilizeSpacer({
      specificOption: {
        size: 'sm',
        direction: 'right',
      },
      type: 'margin',
    })};
  }
`;

export const Left = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  justify-self: start;
`;

export const Link = styled(DefaultLink)``;

export const LogoLink = styled(RouterLink)`
  cursor: pointer;
  height: 1rem;
`;

const iconStyles = css`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
`;

export const MenuIcon = styled(MenuIconDefault)`
  ${iconStyles}
`;

export const Right = styled.div`
  align-self: center;
  display: flex;
  grid-column: 3;
  justify-self: end;

  a {
    margin-right: ${({ theme }) => theme.customTheme.spacing.base};
  }
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
  ${iconStyles}
`;

export const Text = styled(DefaultText)``;
