import type { OnboardingRenewalOffer } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const ONBOARDING_RENEWAL_OFFER_FRAGMENT: TypedDocumentNode<OnboardingRenewalOffer> = gql`
  fragment OnboardingRenewalOffer on OnboardingRenewalOffer {
    effectiveTo
    effectiveFrom
    status
    zeroPointTermPresent
  }
`;

export default ONBOARDING_RENEWAL_OFFER_FRAGMENT;
