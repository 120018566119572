import type { LeaseFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import RENEWAL_OFFER_PRICING_FRAGMENT from './RenewalOfferPricing';

const RENEWAL_OFFER_PRICINGS_FRAGMENT: TypedDocumentNode<LeaseFragment> = gql`
  fragment RenewalOfferPricings on RenewalOfferPricings {
    past {
      ...RenewalOfferPricing
    }
    current {
      ...RenewalOfferPricing
    }
    future {
      ...RenewalOfferPricing
    }
  }
  ${RENEWAL_OFFER_PRICING_FRAGMENT}
`;

export default RENEWAL_OFFER_PRICINGS_FRAGMENT;
