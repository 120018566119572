import type { UserExperimentFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const USER_EXPERIMENT_FRAGMENT: TypedDocumentNode<UserExperimentFragment> = gql`
  fragment UserExperiment on UserExperiment {
    experimentName
    experimentConfig
  }
`;

export default USER_EXPERIMENT_FRAGMENT;
