import type { LeaseFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import { normalizeLeaseStatus } from './LeaseStatus';
import normalizeMoveOutInfo from './MoveOutInfo';
import normalizeResident from './Resident';
import normalizeUnit from './Unit';

export default function normalizeLease(input: WithoutNulls<LeaseFragment>) {
  return {
    id: input.id,
    fromDate: parseISO(input.fromDate).getTime(),
    toDate: input.toDate ? parseISO(input.toDate).getTime() : undefined,
    rent: input.currentRent,
    status: normalizeLeaseStatus(input.status),
    unit: normalizeUnit(input.unit),
    othersOnLease: input.othersOnLease.map(normalizeResident),
    monthToMonth: input.monthToMonth,
    moveOutInfo: input.moveOutInfo
      ? normalizeMoveOutInfo(input.moveOutInfo)
      : undefined,
    renewed_offline: input.renewedOffline,
    notice_given_date: input.noticeGivenDate,
  };
}

export type Lease = ReturnType<typeof normalizeLease>;
