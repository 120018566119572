import ErrorView from '$/containers/Views/Error';
import React from 'react';

function GraphQlErrorBoundary({
  children,
  showError,
}: {
  children: React.ReactNode;
  showError: boolean;
}): JSX.Element {
  return <>{showError ? <ErrorView type="error" /> : children}</>;
}

export default GraphQlErrorBoundary;
