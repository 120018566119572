import DefaultLogo from '$/assets/images/renew-logo.svg';
import MaxWidth from '$/components/Atoms/MaxWidth';
import { Text as DefaultText } from '$/components/Atoms/Typography';
import UtilizeSpacer from '$/styles/theme-styles';
import ArrowLeft from 'remixicon-react/ArrowLeftSLineIcon';
import Information from 'remixicon-react/InformationLineIcon';
import styled, { css } from 'styled-components';

export const Bar = styled.div`
  background: ${({ theme }) => theme.customTheme.colors.white};
  box-shadow: inset 0rem -0.0625rem 0rem rgba(0, 0, 0, 0.1);
  padding: 2rem 0;
  width: 100%;
`;

export const SubBar = styled(Bar)`
  min-height: 5rem;
  display: flex;
  ${UtilizeSpacer({
    specificOption: {
      size: '2xs',
      direction: 'vertical',
    },
    type: 'padding',
  })}
`;

export const Content = styled(MaxWidth)`
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.3fr;
  width: 100%;
`;

export const Button = styled.button`
  background: none;
  border: none;
  border-radius: 0.75rem;
  color: ${({ theme }) => theme.customTheme.colors.night400};
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 3rem;
  height: 3rem;

  :hover {
    color: ${({ theme }) => theme.customTheme.colors.white};
    background-color: ${({ theme }) => theme.customTheme.colors.night400};
  }
`;

export const Center = styled.div`
  grid-column: 2;
  justify-self: center;
  align-self: center;
  display: flex;
`;

export const Left = styled.div`
  align-items: center;
  display: flex;
  grid-column: 1;
  justify-self: start;
`;

export const Logo = styled(DefaultLogo)`
  height: 1.25rem;
`;

const iconStyles = css`
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
`;

export const ArrowLeftIcon = styled(ArrowLeft)`
  ${iconStyles}
`;

export const InformationIcon = styled(Information)`
  ${iconStyles}
`;

export const Text = styled(DefaultText)``;

export const Right = styled.div`
  align-self: center;
  display: flex;
  grid-column: 3;
  justify-self: end;
`;
